import { useMutation } from "@tanstack/react-query";
import { submitApplication } from "@/services/application";

const useSubmitForm = () => {
  const { data, error, isLoading, mutateAsync } =
    useMutation(submitApplication);
  return { data, error, isLoadingSubmit: isLoading, submitForm: mutateAsync };
};

export default useSubmitForm;
