import { TextInput, Text } from "@mantine/core";
import InputMask from "react-input-mask";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import { FloatingLabelInput } from "@/components/Form/FloatingLabelInput/FloatingLabelInput";
import { MobilePush } from "@/components/Common/MobilePush";
import { Container } from "@/components/Form/Container";
import { useProgressBar } from "@/contexts/ProgressBarContext";

const ZipCode = () => {
  const form = useApplicationFormContext();
  useProgressBar(2);

  return (
    <Container>
      <Text size="lg" fw={700}>
        Enter your desired work zip code
      </Text>
      <FloatingLabelInput
        Component={TextInput}
        component={InputMask}
        floatOnFocus
        withAsterisk
        mt="md"
        mask="99999"
        maskPlaceholder=""
        label="Zip Code"
        {...form.getInputProps("zip")}
      />
      <MobilePush />
    </Container>
  );
};

export default ZipCode;
