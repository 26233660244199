import { Text } from "@mantine/core";

export function PasswordInputVisibilityButton({ reveal }) {
  if (reveal) {
    return (
      <Text size="xs" fw={700} color="black">
        Hide
      </Text>
    );
  }

  return (
    <Text size="xs" fw={700} color="black">
      Show
    </Text>
  );
}
