import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

import variables from "@/config/variables";

const { persistAtom } = recoilPersist({
  key: variables.localStorage.APPLICATION_FORM,
  storage: localStorage,
});

const MAX_STEPS = 15;

const applicationState = atom({
  key: "applicationState",
  default: {
    currentStep: 0,
    maxSteps: MAX_STEPS,
    canContinue: false,
    isFinished: false,
    data: {
      currentStep: 0,
      firstName: undefined,
      lastName: undefined,
      hearAboutUs: undefined,
      phone: undefined,
      agreedToReceiveTexts: true,
      marketId: undefined,
      dob: undefined,
      reliableTransportation: undefined,
      collegeLevel: "",
      paidSittingExperience: undefined,
      sitterStatus: null,
      imageData: null,
      imageUrl: null,
      noMiddleName: false,
      address1: undefined,
      address2: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
      ssn: undefined,
      driversLicenseNo: undefined,
      driversLicenseState: undefined,
      fcraAgreement: false,
      copyRequested: false,
      disclosureAgreement: false,
      zipPrefilled: false,
    },
  },
  effects_UNSTABLE: [persistAtom],
});

export default applicationState;
