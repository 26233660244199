import { Text, ScrollArea, Flex, Group, Paper, Checkbox } from "@mantine/core";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { Container } from "@/components/Form/Container";

const FCRADisclosure = () => {
  const form = useApplicationFormContext();
  useProgressBar(12);

  return (
    <Container>
      <Text size="lg" fw={700}>
        FCRA Disclosure
      </Text>
      <Paper mt="md" shadow="sm" radius="sm" withBorder>
        <Group p="xs">
          <ScrollArea style={{ height: 250 }} type="auto" p="0">
            <Flex
              gap="md"
              justify="flex-start"
              align="flex-start"
              direction="row"
              wrap="wrap"
            >
              <Text
                style={{ fontSize: "12px", overflowWrap: "anywhere" }}
                color="placeholder.0"
              >
                <h3 class="my-4" style={{ marginTop: 0 }}>
                  A Summary of Your Rights Under the Fair Credit Reporting Act
                </h3>
                <p class="scrolling-content mb-8">
                  The federal Fair Credit Reporting Act (FCRA) promotes the
                  accuracy, fairness, and privacy of information in the files of
                  consumer reporting agencies.
                  <br />
                  There are many types of consumer reporting agencies, including
                  credit bureaus and specialty agencies (such as agencies that
                  sell information about check writing histories, medical
                  records, and rental history records).
                  <br />
                  Here is a summary of your major rights under the FCRA. For
                  more information, including information about additional
                  rights, go to{" "}
                  <a href="www.consumerfinance.gov/learnmore">
                    www.consumerfinance.gov/learnmore{" "}
                  </a>{" "}
                  or write to: Consumer Financial Protection Bureau, 1700 G
                  Street N.W., Washington, DC 20552.
                  <ul>
                    <li>
                      You must be told if information in your file has been used
                      against you. Anyone who uses a credit report or another
                      type of consumer report to deny your application for
                      credit, insurance, or employment – or to take another
                      adverse action against you – must tell you, and must give
                      you the name, address, and phone number of the agency that
                      provided the information.{" "}
                    </li>
                    <li>
                      You have the right to know what is in your file. You may
                      request and obtain all the information about you in the
                      files of a consumer reporting agency (your "file
                      disclosure"). You will be required to provide proper
                      identification, which may include your Social Security
                      number. In many cases, the disclosure will be free. You
                      are entitled to a free file disclosure if:{" "}
                      <ul>
                        <li>
                          a person has taken adverse action against you because
                          of information in your credit report;
                        </li>
                        <li>
                          you are the victim of identity theft and place a fraud
                          alert in your file;
                        </li>
                        <li>
                          your file contains inaccurate information as a result
                          of fraud; you are on public assistance;
                        </li>
                        <li>
                          you are unemployed but expect to apply for employment
                          within 60 days. In addition, all consumers are
                          entitled to one free disclosure every 12 months upon
                          request from each nationwide credit bureau and from
                          nationwide specialty consumer reporting agencies. See{" "}
                          <a href="www.consumerfinance.gov/learnmore">
                            www.consumerfinance.gov/learnmore{" "}
                          </a>
                          for additional information.
                        </li>
                      </ul>
                    </li>
                    <li>
                      You have the right to ask for a credit score. Credit
                      scores are numerical summaries of your credit-worthiness
                      based on information from credit bureaus. You may request
                      a credit score from consumer reporting agencies that
                      create scores or distribute scores used in residential
                      real property loans, but you will have to pay for it. In
                      some mortgage transactions, you will receive credit score
                      information for free from the mortgage lender.{" "}
                    </li>
                    <li>
                      You have the right to dispute incomplete or inaccurate
                      information. If you identify information in your file that
                      is incomplete or inaccurate, and report it to the consumer
                      reporting agency, the agency must investigate unless your
                      dispute is frivolous. See{" "}
                      <a href="www.consumerfinance.gov/learnmore">
                        www.consumerfinance.gov/learnmore{" "}
                      </a>
                      for an explanation of dispute procedures.
                    </li>
                    <li>
                      Consumer reporting agencies must correct or delete
                      inaccurate, incomplete, or unverifiable information.
                      Inaccurate, incomplete or unverifiable information must be
                      removed or corrected, usually within 30 days. However, a
                      consumer reporting agency may continue to report
                      information it has verified as accurate.
                    </li>
                    <li>
                      Consumer reporting agencies may not report outdated
                      negative information. In most cases, a consumer reporting
                      agency may not report negative information that is more
                      than seven years old, or bankruptcies that are more than
                      10 years old.
                    </li>
                    <li>
                      Access to your file is limited. A consumer reporting
                      agency may provide information about you only to people
                      with a valid need -- usually to consider an application
                      with a creditor, insurer, employer, landlord, or other
                      business. The FCRA specifies those with a valid need for
                      access.
                    </li>
                    <li>
                      You must give your consent for reports to be provided to
                      employers. A consumer reporting agency may not give out
                      information about you to your employer, or a potential
                      employer, without your written consent given to the
                      employer. Written consent generally is not required in the
                      trucking industry. For more information, go to{" "}
                      <a href="www.consumerfinance.gov/learnmore">
                        www.consumerfinance.gov/learnmore{" "}
                      </a>
                      .
                    </li>
                    <li>
                      You may limit "prescreened" offers of credit and insurance
                      you get based on information in your credit report.
                      Unsolicited "prescreened" offers for credit and insurance
                      must include a toll-free phone number you can call if you
                      choose to remove your name and address from the lists
                      these offers are based on. You may opt out with the
                      nationwide credit bureaus at 1-888-5-OPTOUT
                      (1-888-567-8688).{" "}
                    </li>
                    <li>
                      You may seek damages from violators. If a consumer
                      reporting agency, or, in some cases, a user of consumer
                      reports or a furnisher of information to a consumer
                      reporting agency violates the FCRA, you may be able to sue
                      in state or federal court.{" "}
                    </li>
                    <li>
                      Identity theft victims and active duty military personnel
                      have additional rights. For more information, visit{" "}
                      <a href="www.consumerfinance.gov/learnmore">
                        www.consumerfinance.gov/learnmore{" "}
                      </a>
                      . States may enforce the FCRA, and many states have their
                      own consumer reporting laws. In some cases, you may have
                      more rights under state law. For more information, contact
                      your state or local consumer protection agency or your
                      state Attorney General. For information about your federal
                      rights, contact:{" "}
                      <p>
                        FTC Regional Office for region in which the creditor
                        operates or Federal Trade Commission: Consumer Response
                        Center – FCRA
                      </p>
                      <p>Washington, DC 20580 (877) 382-4357</p>
                    </li>
                  </ul>
                </p>
              </Text>
            </Flex>
          </ScrollArea>
        </Group>
      </Paper>
      <Checkbox
        mt="md"
        label="I acknowledge receipt of the Summary of Your Rights Under the Fair Credit Reporting
        Act (FCRA) and certify that I have read and understand the document"
        checked={form.values.fcraAgreement}
        className="align-top"
        {...form.getInputProps("fcraAgreement")}
      />
    </Container>
  );
};

export default FCRADisclosure;
