import { gql } from "graphql-request";
import { showNotification } from "@mantine/notifications";

import { gqlPublicRequest } from "@/utils/request";

export const getApplicationStatistics = async () => {
  const query = gql`
    query ApplicationStatisitcs {
      applicationStatistics {
        averageJobRate
        completedJobsCount
        jobsPerWeekCount
        sittersCount
      }
    }
  `;

  try {
    const data = await gqlPublicRequest.request(query);
    return data?.applicationStatistics;
  } catch (err) {
    showNotification({
      color: "red",
      title: "Data fetch error",
      message: "Something wen't wrong, please refresh the page 😟",
    });
  }
  return {};
};
