import { TextInput, Text, Checkbox, createStyles } from "@mantine/core";
import InputMask from "react-input-mask";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import { FloatingLabelInput } from "@/components/Form/FloatingLabelInput/FloatingLabelInput";
import { MobilePush } from "@/components/Common/MobilePush";
import { Container } from "@/components/Form/Container";
import { useProgressBar } from "@/contexts/ProgressBarContext";

const useStyles = createStyles(() => ({
  note: {
    fontSize: "13px",
    margin: "0 auto",
    maxWidth: "300px",
  },
}));

const PhoneNumber = () => {
  const { classes } = useStyles();

  const form = useApplicationFormContext();
  useProgressBar(1);

  return (
    <Container>
      <Text size="lg" fw={700}>
        Enter your phone number
      </Text>
      <FloatingLabelInput
        Component={TextInput}
        component={InputMask}
        floatOnFocus
        withAsterisk
        mt="md"
        mask="999-999-9999"
        maskPlaceholder=""
        label="Phone Number"
        {...form.getInputProps("phone")}
      />
      <Text
        mt="md"
        color="text.2"
        fw={600}
        align="center"
        className={classes.note}
      >
        Our texts inform you about available jobs in your area and other
        opportunities
      </Text>
      <MobilePush />

      <Checkbox
        mt="md"
        label="I agree to receive texts from Wyndy."
        checked={form.values.agreedToReceiveTexts ?? true}
        {...form.getInputProps("agreedToReceiveTexts")}
      />
    </Container>
  );
};

export default PhoneNumber;
