import { useQuery } from "@tanstack/react-query";

import { getUserData } from "@/services/application";
import useAuth from "@/hooks/useAuth";

const useGetUserData = () => {
  const { auth } = useAuth();

  const { data, refetch, error, isLoading } = useQuery(
    ["application"],
    getUserData,
    {
      enabled: auth?.isAuth,
      refetchOnWindowFocus: false,
    }
  );
  return { data, refetch, error, isLoading };
};

export default useGetUserData;
