import { Text, Space, Button, Flex, Anchor, Center } from "@mantine/core";

import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";

import { useGetStripeData, useCreateExternalAccount } from "@/hooks/useStripe";
import Loader from "@/components/Loader/Loader";

const AddPaymentMethod = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const { data, isLoading } = useGetStripeData({
    returnUrl: window.location.href,
  });
  const { saveExternalAccount, error, loading } = useCreateExternalAccount();

  const handleGoBack = () => navigate(-1);

  if (isLoading) {
    return <Loader />;
  }

  if (data?.cards?.length > 0) {
    // Sitter has already added a card
    navigate("/dashboard");
  }

  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (elements) {
      const cardElement = elements.getElement(CardElement);
      await saveExternalAccount(cardElement);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Text size="md">
        Use the form below to add a debit card so we can pay you!
      </Text>
      <Text size="md">Credit cards are not accepted</Text>
      <Space h="xl" />
      <form onSubmit={onFormSubmit}>
        <CardElement />
        <Text c="red">{error}</Text>
        <Space h="md" />
        <Button
          fullWidth
          mt="lg"
          type="submit"
          loading={loading}
          disabled={!stripe || !elements}
        >
          Save
        </Button>
        <Flex mt="md" direction="column" align="center">
          <Center>
            <Anchor onClick={handleGoBack}>Back</Anchor>
          </Center>
        </Flex>
      </form>
    </div>
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AddPaymentMethodWrapper = () => (
  <Elements stripe={stripePromise}>
    <AddPaymentMethod />
  </Elements>
);

export default AddPaymentMethodWrapper;
