import {
  PasswordInput,
  Group,
  Button,
  TextInput,
  Anchor,
  Text,
  LoadingOverlay,
  Box,
  Flex,
} from "@mantine/core";

import useRegistration from "@/hooks/useRegister";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { FloatingLabelInput } from "@/components/Form/FloatingLabelInput/FloatingLabelInput";
import { Form } from "@/components/Form/Form";
import { Container } from "@/components/Form/Container";
import { InfoCards } from "@/components/Common/InfoCards";
import { PasswordInputVisibilityButton } from "@/components/Form/PasswordInputVisibilityButton";

const RegisterPage = () => {
  const { register, loading, form, isButtonDisabled } = useRegistration();

  useProgressBar(0);

  return (
    <Container>
      <LoadingOverlay visible={loading} />
      <Text size="xl" fw={700}>
        Apply now to become a sitter
      </Text>
      <Text size="md" mt="xs" fw={500}>
        Sitters can earn $100+ per job
      </Text>
      <Form onSubmit={form.onSubmit(register)}>
        <FloatingLabelInput
          Component={TextInput}
          mt="md"
          label="First Name"
          {...form.getInputProps("firstName")}
        />
        <FloatingLabelInput
          Component={TextInput}
          mt="md"
          label="Last Name"
          {...form.getInputProps("lastName")}
        />
        <FloatingLabelInput
          Component={TextInput}
          mt="md"
          label="Email"
          placeholder="example@mail.com"
          {...form.getInputProps("email")}
        />
        <FloatingLabelInput
          Component={PasswordInput}
          mt="md"
          label="Create Password"
          placeholder="Create Password"
          autoComplete="new-password"
          visibilityToggleIcon={({ reveal }) => (
            <PasswordInputVisibilityButton reveal={reveal} />
          )}
          {...form.getInputProps("password")}
        />

        <Group position="center" mt="md">
          <Button fullWidth type="submit" disabled={isButtonDisabled}>
            Start Application
          </Button>
        </Group>

        <Flex direction="column" w="100%" mt="md" mb="xl">
          <Text color="gray" size="xs" fw={500} align="center">
            By continuing, you agree to Wyndy’s
          </Text>
          <Text color="gray" size="xs" fw={500} align="center">
            <Anchor
              color="gray"
              underline="always"
              size="xs"
              href={`${process.env.REACT_APP_WYNDY_APP_URL}/terms-of-use.pdf`}
              target="_blank"
            >
              Terms of Use
            </Anchor>{" "}
            and{" "}
            <Anchor
              size="xs"
              color="gray"
              underline="always"
              href={`${process.env.REACT_APP_WYNDY_APP_URL}/privacy-policy.pdf`}
              target="_blank"
            >
              Privacy Policy
            </Anchor>
          </Text>
        </Flex>

        <Box mt="xl">
          <InfoCards />
        </Box>
      </Form>
    </Container>
  );
};

export default RegisterPage;
