import { gql } from "graphql-request";
import { showNotification } from "@mantine/notifications";

import { gqlRequest } from "@/utils/request";

export const getPaymentIntent = async () => {
  const mutation = gql`
    mutation SittersCreatePaymentIntent {
      sitter {
        createPaymentIntent {
          paymentIntent {
            id
            amount
            clientSecret
          }
          membershipPrice {
            recurring {
              interval
            }
          }
        }
      }
    }
  `;

  try {
    const data = await gqlRequest.request(mutation);
    return data?.sitter?.createPaymentIntent;
  } catch (err) {
    console.log(err);
    showNotification({
      color: "red",
      title: "Save Error",
      message: "Something wen't wrong, please refresh the page 😟",
    });
  }
  return {};
};
