import * as Yup from "yup";

const formPreValidation = {
  getScheme: (originSchema) => {
    const schemaFields = {};

    Object.keys(originSchema?.fields ?? {}).forEach((key) => {
      const field = originSchema.fields[key];
      const tests = field.tests.filter(
        (test) =>
          test.OPTIONS.name === "required" ||
          test.OPTIONS.name === "pre-validation-len"
      );
      if (tests.length > 0) {
        let fieldSchema = Yup.string();
        tests.forEach((test) => {
          if (test.OPTIONS.name === "required") {
            fieldSchema = fieldSchema.required(test.OPTIONS.message);
          }
          if (test.OPTIONS.name === "pre-validation-len") {
            fieldSchema = fieldSchema.test(
              test.OPTIONS.name,
              test.OPTIONS.message,
              test.OPTIONS.test
            );
          }
        });
        schemaFields[key] = fieldSchema;
      }
    });

    const schema = Yup.object().shape(schemaFields);

    return {
      schema,
      async isValid(values) {
        try {
          return await schema.isValid(values);
        } catch (error) {
          return false;
        }
      },
    };
  },
};

export default formPreValidation;
