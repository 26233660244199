import { createStyles } from "@mantine/core";

const useStyles = createStyles(() => ({
  form: {
    display: "flex",
    flexDirection: "column",
    [`@media (max-width: 480px)`]: {
      flex: "1",
    },
  },
}));

export const Form = (props) => {
  const { classes } = useStyles();
  return <form className={classes.form} {...props} />;
};
