import { useMutation } from "@tanstack/react-query";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";

import { resendConfirmationEmailService } from "@/services/auth";

const schema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Invalid email"),
});

const useResendConfirmationEmail = () => {
  const navigate = useNavigate();
  const form = useForm({
    clearInputErrorOnChange: true,
    validate: yupResolver(schema),
    initialValues: {
      email: "",
    },
  });

  const { isLoading, mutate } = useMutation({
    mutationFn: resendConfirmationEmailService,
  });

  const handleResendConfirmationEmail = ({ email }) =>
    mutate(
      {
        email,
      },
      {
        onSuccess: () => {
          showNotification({
            color: "green",
            autoClose: 10000,
            title: "Password reset",
            icon: <FaCheck />,
            message:
              "If the entered email address exists then you should receive an email with the confirm account instructions.",
          });
          navigate("/login");
        },
        onError: () => {
          showNotification({
            color: "red",
            title: "Error",
            message: "Something wen't wrong, please retry 😟",
          });
        },
      }
    );

  return {
    form,
    resendConfirmationEmail: handleResendConfirmationEmail,
    loading: isLoading,
  };
};

export default useResendConfirmationEmail;
