const colors = {
  brand: [
    "#5ec6c9",
    "#5FCCDB",
    "#44CADC",
    "#2AC9DE",
    "#1AC2D9",
    "#11B7CD",
    "#09ADC3",
    "#0E99AC",
    "#128797",
    "#147885",
  ],
  "background-white": ["#ffffff"],
  "primary-cta": ["#11395B", "#0A2943"],
  "accent-cta": ["#FFAA4F", "#F99529"],
  "strong-cta": ["#00C0C1", "#00A5A6"],
  base: ["#FFFFFF", "#EAEAEA"],
  text: ["#000000", "#2E2E2E", "#B2B2B2", "#808080"],
  "wyndy-gray": ["#A6AAA7"],
  "wyndy-red": ["#E46666"],
  "soft-teal": ["#60C6C9"],
  "wyndy-pink": ["#F9BDBE"],
  "soft-orange": ["#FDCD9D"],
  placeholder: ["#7f7f7f"],
  "input-border": ["#cccccc"],
};

const defaultTheme = {
  colorScheme: "light",
  primaryColor: "brand",
  fontFamily: "'Poppins', sans-serif",
  colors: colors,
  components: {
    Header: {
      styles: {
        root: {
          borderBottom: 0,
        },
      },
    },
    Input: {
      styles: {
        input: {
          height: "55px",
          padding: "14px 16px 13px",
          fontSize: 19,
          fontWeight: 500,
          border: `1px solid ${colors["input-border"][0]}`,
          borderRadius: 12,
          "&:focus": {
            borderColor: colors["strong-cta"][1],
          },
          "&::placeholder": {
            color: "#7f7f7f",
          },
        },
      },
    },
    PasswordInput: {
      styles: {
        input: {
          padding: 0,
          border: `1px solid ${colors["input-border"][0]}`,
          borderRadius: 12,
          "&:focus": {
            borderColor: colors["strong-cta"][1],
          },
        },
        innerInput: {
          height: "55px",
          padding: "14px 16px 13px",
          fontSize: 19,
          fontWeight: 500,
        },
        rightSection: {
          width: 55,
        },
        visibilityToggle: {
          width: "auto",
          minWidth: "auto",
          "& svg": {
            width: 20,
            height: 20,
          },
        },
      },
    },
    Textarea: {
      styles: {
        root: {
          borderRadius: 12,
          overflow: "hidden",
        },
        input: {
          height: "auto",
        },
      },
    },
    Button: {
      styles: {
        root: {
          height: 60,
          fontSize: 19,
          fontWeight: 600,
          borderRadius: 30,
          color: colors["base"][0],
          backgroundColor: colors["primary-cta"][0],
          transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
          "&:hover": {
            backgroundColor: colors["primary-cta"][1],
            boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.12)",
          },
          "&.outlined": {
            color: colors["primary-cta"][0],
            backgroundColor: colors["base"][0],
            border: `1px solid ${colors["primary-cta"][0]}`,
          },
          "&.outlined:hover": {
            backgroundColor: "#e7ebee",
          },
          "&[data-disabled]": {
            color: "#ffffff",
            backgroundColor: "#cfd7de",
          },
        },
      },
    },
    ActionIcon: {
      styles: {
        root: {
          userSelect: "none",
          "&:active": {
            transform: "none",
          },
        },
      },
    },
    Checkbox: {
      styles: {
        root: {
          "&.align-top .mantine-Checkbox-body": {
            alignItems: "flex-start",
          },
        },
        body: {
          alignItems: "center",
        },
        inner: {
          width: 32,
          height: 32,
        },
        input: {
          width: 32,
          height: 32,
          borderRadius: 16,
          cursor: "pointer",
          "&:checked": {
            backgroundColor: colors["text"][0],
            borderColor: colors["text"][0],
          },
        },
        label: {
          fontSize: 15,
          fontWeight: 500,
          cursor: "pointer",
        },
        icon: {
          width: 15,
          height: 15,
        },
        error: {
          fontWeight: 700,
          color: "#ff0000",
          fontSize: 11,
        },
      },
    },
    Anchor: {
      styles: {
        root: {
          fontWeight: 500,
        },
      },
    },
    Divider: {
      styles: {
        label: {
          fontWeight: 500,
        },
      },
    },
    Paper: {
      styles: {
        root: {
          borderRadius: 12,
          boxShadow: "none",
          borderColor: colors["input-border"][0],
        },
      },
    },
    Notification: {
      styles: {
        root: {
          borderRadius: "16px",
          backdropFilter: "blur(4px)",
          padding: "25px 15px 25px 25px !important",
        },
        title: {
          fontWeight: 700,
        },
        description: {
          fontWeight: 500,
          fontSize: "12px",
        },
        closeButton: {
          fontWeight: 500,
          backgroundColor: "#0000000D",
          borderRadius: "14px",
          "&:hover": {
            backgroundColor: "#0000001c",
          },
        },
      },
    },
    Avatar: {
      styles: {
        placeholder: {
          backgroundColor: "#e8e8e8",
        },
      },
    },
  },
};

export default defaultTheme;
