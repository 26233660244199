import { createStyles, Progress } from "@mantine/core";
import { useContext } from "react";

import { ProgressBarContext } from "@/contexts/ProgressBarContext";
import variables from "@/config/variables";

const useStyles = createStyles(() => ({
  root: {
    height: 3,
    backgroundColor: "rgba(0, 0, 0, 0.02)",
  },
  bar: {
    borderRadius: "0 !important",
    backgroundColor: "#00C0C1",
  },
}));

export const ProgressBar = (props) => {
  const { classes } = useStyles();
  const progressBarContext = useContext(ProgressBarContext);

  const calcProgressValue = () => {
    if (progressBarContext.currentStep === null) {
      return 0;
    }
    return (progressBarContext.currentStep / variables.stepsCount) * 100;
  };

  return progressBarContext.currentStep === null ? null : (
    <Progress
      classNames={{
        root: classes.root,
        bar: classes.bar,
      }}
      value={calcProgressValue()}
      {...props}
    />
  );
};
