import { selector } from "recoil";

import applicationState from "@/pages/Application/atoms/application.atom";

const currentStepState = selector({
  key: "currentStepState", // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const state = get(applicationState);

    return state.currentStep;
  },
});

export default currentStepState;
