import { Group, Button, Loader, Center, Anchor } from "@mantine/core";
import { Outlet } from "react-router-dom";
import { FaBan } from "react-icons/fa";

import { ApplicationFormProvider } from "@/pages/Application/form-context";
import useForm from "@/pages/Application/hooks/useForm";
import { Form } from "@/components/Form/Form";
import ProcessExternalZipCode from "@/pages/Application/ProcessExternalZipCode";

const RegisterPage = ({ disableSkipButton }) => {
  const {
    form,
    loading,
    goToNextStep,
    goBackStep,
    isFirstStep,
    isMaxStep,
    submit,
    optOutApplication,
    canSkip,
    hideButtons,
    canContinueApplication,
    canEndApplication,
    backButtonDisabled,
  } = useForm();

  const renderLoader = () => (
    <Center p="xl" sx={{ minHeight: "50vh" }}>
      <Loader />
    </Center>
  );

  const renderActionButtons = () => (
    <>
      {!isMaxStep && (
        <Button
          fullWidth
          onClick={goToNextStep}
          disabled={!canContinueApplication}
        >
          Continue
        </Button>
      )}
      {isMaxStep && (
        <Button fullWidth onClick={submit}>
          Submit Application
        </Button>
      )}
      {canSkip && !disableSkipButton && (
        <Button
          compact
          fullWidth
          color="red"
          variant="subtle"
          leftIcon={<FaBan />}
          onClick={optOutApplication}
        >
          I'd rather not provide this info
        </Button>
      )}
      {canEndApplication && (
        <Button
          compact
          fullWidth
          color="red"
          variant="subtle"
          leftIcon={<FaBan />}
          onClick={optOutApplication}
        >
          No thanks
        </Button>
      )}
    </>
  );

  const renderContent = () => (
    <>
      <Form>
        <Outlet />
      </Form>
      <Group position="center" mt="md">
        {!hideButtons && renderActionButtons()}
        {!isFirstStep && !backButtonDisabled && (
          <Center>
            <Anchor onClick={goBackStep}>Back</Anchor>
          </Center>
        )}
      </Group>
    </>
  );

  return (
    <ApplicationFormProvider form={form}>
      <ProcessExternalZipCode />
      {loading ? renderLoader() : renderContent()}
    </ApplicationFormProvider>
  );
};

export default RegisterPage;
