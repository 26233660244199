import { Text, List, Group, Button, Anchor } from "@mantine/core";

import { Container } from "@/components/Form/Container";
import { MobilePush } from "@/components/Common/MobilePush";

const BasicQualificationsDenied = () => {
  return (
    <Container>
      <Text size="xl" fw={700}>
        Thank you for your interest in Wyndy!
      </Text>
      <Text size="md" mt="md" weight="500">
        Unfortunately, you don’t meet the basic qualifications to work as a
        Wyndy.
      </Text>
      <Text mt="xl" size="md" align="left" weight="600">
        Wyndys must:
      </Text>
      <List mr="xl">
        <List.Item>
          <Text size="md" align="left" weight="500">
            Be at least 18 years old
          </Text>
        </List.Item>
        <List.Item>
          <Text size="md" align="left" weight="500">
            Have one year of babysitting experience
          </Text>
        </List.Item>
        <List.Item>
          <Text size="md" align="left" weight="500">
            Have reliable transportation
          </Text>
        </List.Item>
        <List.Item>
          <Text size="md" align="left" weight="500">
            Be enrolled in or have attended college
          </Text>
        </List.Item>
      </List>
      <Group position="center" mt="xl" mb="xl">
        <Text size="md" align="left" weight="500">
          If you believe there was an error or need to update your application,
          please contact our support team.
        </Text>
      </Group>

      <MobilePush />

      <Anchor
        href="mailto:support@wyndy.com"
        pt="md"
        display="block"
        underline={false}
        style={{ width: "100%" }}
      >
        <Button fullWidth>Contact Support</Button>
      </Anchor>
    </Container>
  );
};

export default BasicQualificationsDenied;
