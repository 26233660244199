import { useQuery } from "@tanstack/react-query";

import { getMarkets } from "@/services/markets";

const useGetMarkets = () => {
  const { data, refetch, error, isLoading } = useQuery(
    ["markets"],
    getMarkets,
    {
      refetchOnWindowFocus: false,
    }
  );
  return { markets: data, refetch, error, isLoadingMarkets: isLoading };
};

export default useGetMarkets;
