import {
  Text,
  FileButton,
  Avatar,
  Center,
  LoadingOverlay,
  Flex,
  Anchor,
  Image,
} from "@mantine/core";
import useProfilePhotoUpload from "@/pages/Application/hooks/useProfilePhotoUpload";
import image from "@/assets/images/image.svg";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { Container } from "@/components/Form/Container";

const ProfilePhoto = () => {
  const { form, upload, uploading } = useProfilePhotoUpload();
  const imageUrl = form?.values?.imageUrl;
  useProgressBar(5);

  return (
    <Container>
      <LoadingOverlay visible={uploading} />
      <Text size="lg" fw={700}>
        Upload a Profile Photo
      </Text>
      <Center>
        <FileButton
          mt="xl"
          accept="image/png,image/jpeg"
          {...form.getInputProps("imageData")}
          onChange={upload}
        >
          {(props) => (
            <Anchor {...props}>
              <Flex justify="center" align="center" direction="column">
                <Avatar
                  size={350}
                  radius={10}
                  color={Object.keys(form.errors).length ? "red" : "#e8e8e8"}
                  src={imageUrl}
                >
                  <Image width={50} src={image} />
                </Avatar>
                <Text size="sm" mt="md" color="primary-cta.0" fw={700}>
                  {imageUrl ? "Change photo" : "+ Upload Image"}
                </Text>
              </Flex>
            </Anchor>
          )}
        </FileButton>
      </Center>
    </Container>
  );
};

export default ProfilePhoto;
