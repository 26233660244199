import { useEffect } from "react";
import variables from "@/config/variables";
import { useApplicationFormContext } from "@/pages/Application/form-context";
import useForm from "@/pages/Application/hooks/useForm";

const ProcessExternalZipCode = () => {
  const rootForm = useForm();
  const form = useApplicationFormContext();

  useEffect(() => {
    if (rootForm.loading) {
      return;
    }
    const zipCode = window.localStorage.getItem(
      variables.localStorage.EXTERNAL_ZIP_CODE
    );
    if (zipCode && !form.values.zip) {
      form.setFieldValue("zip", zipCode);
      form.setFieldValue("zipPrefilled", true);
    }
  }, [form, rootForm]);

  useEffect(() => {
    if (form.values.zip && form.values.zipPrefilled) {
      window.localStorage.removeItem(variables.localStorage.EXTERNAL_ZIP_CODE);
    }
  }, [form]);

  return null;
};

export default ProcessExternalZipCode;
