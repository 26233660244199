import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { showNotification } from "@mantine/notifications";

import { registrationService } from "@/services/registration";
import useAuth from "@/hooks/useAuth";
import formPreValidation from "@/utils/formPreValidation";

const schema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().required("Email is required").email("Invalid email"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long"),
});

const preValidationSchema = formPreValidation.getScheme(schema);

const useRegistration = () => {
  const { setAuth } = useAuth();
  const form = useForm({
    clearInputErrorOnChange: true,
    validate: yupResolver(schema),
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      privacyConsent: true,
    },
  });

  const { isLoading, mutate } = useMutation({
    mutationFn: registrationService,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    preValidationSchema.isValid(form.values).then((isValid) => {
      if (isValid) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values]);

  const handleRegister = async ({ email, password, firstName, lastName }) =>
    await mutate(
      {
        email,
        password,
        passwordConfirmation: password,
        privacyConsent: true,
        firstName,
        lastName,
      },
      {
        onSuccess: (data) => {
          setAuth({
            token: data?.authentication_token,
            firstName,
            lastName,
          });
          window.location.assign("/introduction");
        },
        onError: (err) => {
          const errors = getErrorsFromReponse(err?.response?.data?.errors);
          if (errors.length === 0) {
            // Something unknown went wrong.
            showNotification({
              color: "red",
              title: "Registration failed",
              message: "Something wen't wrong with the registration 😟",
            });
          }
          errors.forEach((error) => form.setErrors(error));
        },
      }
    );

  const getErrorsFromReponse = (errs) => {
    const errorMessages = [];

    errs?.forEach((errorObject) => {
      if (
        errorObject.source?.pointer?.includes("email") &&
        errorObject.detail === "already registered"
      ) {
        errorMessages.push({ email: "Email is taken" });
      }
    });

    return errorMessages;
  };

  return {
    form,
    register: handleRegister,
    loading: isLoading,
    isButtonDisabled: isButtonDisabled,
  };
};

export default useRegistration;
