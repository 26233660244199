import { Text, Textarea } from "@mantine/core";
import { useApplicationFormContext } from "@/pages/Application/form-context";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { Container } from "@/components/Form/Container";
import CharactersCounter from "@/components/Form/CharactersCounter";
import { FloatingLabelTextarea } from "@/components/Form/FloatingLabelTextarea/FloatingLabelTextarea";

const WhyJoin = () => {
  const form = useApplicationFormContext();
  useProgressBar(7);

  return (
    <Container>
      <Text size="lg" fw={700}>
        Why do you want to join Wyndy?
      </Text>
      <FloatingLabelTextarea
        Header={
          <>
            <Text mt="md" fs={"19px"} fw={500}>
              Describe the types of jobs you’re looking for and tell us about
              yourself
              {/*{" "}
              <Text size="md" span={true} c="red">
                *
              </Text>*/}
            </Text>
          </>
        }
        Component={Textarea}
        placeholder="Briefly describe the types of jobs you’re looking for and yourself..."
        labelType={"data-floating-bg"}
        mt="xs"
        label={""}
        minRows={8}
        {...form.getInputProps("whyDoYouWantToBeAWyndy")}
      />
      <CharactersCounter
        value={form?.values?.whyDoYouWantToBeAWyndy}
        requiredLen={100}
      />
    </Container>
  );
};

export default WhyJoin;
