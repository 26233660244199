import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getUserStripeAccount, createExternalAccount } from "services/stripe";
import { useStripe } from "@stripe/react-stripe-js";

import useAuth from "@/hooks/useAuth";

export const useGetStripeData = ({ returnUrl }) => {
  const { auth } = useAuth();

  const { data, refetch, error, isLoading } = useQuery(
    ["getSitterStripeAccount"],
    () => getUserStripeAccount({ returnUrl }),
    {
      enabled: auth?.isAuth,
      refetchOnWindowFocus: false,
    }
  );
  return { data, refetch, error, isLoading };
};

export const useCreateExternalAccount = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const saveExternalAccount = async (cardElement) => {
    setLoading(true);
    try {
      const { error, token } = await stripe.createToken(cardElement, {
        currency: "usd",
      });

      if (error) {
        setError(error);
        throw error;
      }

      const { errors } = await createExternalAccount({ cardToken: token.id });

      if (errors.length) {
        setError(errors.map((e) => e.message));
      } else navigate("/dashboard");
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  return { saveExternalAccount, error, loading };
};
