import { createStyles, Text } from "@mantine/core";

const useStyles = createStyles(() => ({
  label: {
    fontSize: "13px",
  },
}));

const CharactersCounter = ({ value, requiredLen }) => {
  const { classes } = useStyles();
  const valueLen = parseInt(value?.length ?? 0);

  if (valueLen >= requiredLen) {
    return "";
  } else if (valueLen === 0) {
    return (
      <Text mt="xs" size="xs" color="text.2" fw={700} className={classes.label}>
        [{requiredLen + " character required"}]
      </Text>
    );
  } else {
    return (
      <Text mt="xs" size="xs" color="text.2" fw={700} className={classes.label}>
        [{requiredLen - valueLen + " more characters required"}]
      </Text>
    );
  }
};

export default CharactersCounter;
