import { useQuery } from "@tanstack/react-query";

import { getColleges } from "@/services/colleges";

const useGetColleges = (title) => {
  const { data, isLoading } = useQuery(
    ["colleges", title],
    () => getColleges(title),
    {
      refetchOnWindowFocus: false,
    }
  );

  return { colleges: data, isLoadingColleges: isLoading };
};

export default useGetColleges;
