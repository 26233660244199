import { Text, Textarea } from "@mantine/core";
import { useEffect } from "react";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { Container } from "@/components/Form/Container";
import CharactersCounter from "@/components/Form/CharactersCounter";
import { FloatingLabelTextarea } from "@/components/Form/FloatingLabelTextarea/FloatingLabelTextarea";

// import { FaArrowRight } from "react-icons/fa";
// import { submitInterviewVideo } from "services/application";
// import { Widget } from "@myinterview/widget-react";

// const isDevelopment = process.env.REACT_APP_ENV === "development";

const VideoInterview = () => {
  // const { goToNextStep } = useForm();
  const form = useApplicationFormContext();
  // const interviewCompleted = form.values.myInterviewVideoId;
  useProgressBar(6);

  useEffect(() => {
    // This is a test video ID that is only used to keep reporting working while we phase out the video interview.
    form.setFieldValue(
      "myInterviewVideoId",
      "87cdd029-bbc3-45c0-ad01-d1d77aec3b81"
    );
    form.setFieldValue("videoSubmittedAt", new Date().toISOString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleSkipInterview = () => {
  //   goToNextStep();
  // };

  // const renderCompletedMessage = () => (
  //   <>
  //     <Text size="lg" align="left" weight="500">
  //       Your interview has been submitted for review!
  //     </Text>
  //     <Button fullWidth rightIcon={<FaArrowRight />} onClick={goToNextStep}>
  //       Next step
  //     </Button>
  //   </>
  // );

  // const renderWidget = () => (
  //   <Widget
  //     candidate={{
  //       email: form.values.email,
  //       username: `${form.values.firstName} ${form.values.lastName}`,
  //     }}
  //     buttonStyle={{ color: "white", backgroundColor: "#09ADC3" }}
  //     config={{
  //       auth: form.values.myInterviewSignature,
  //       introVideo: "https://www.youtube.com/watch?v=I99f98Z8OZc",
  //       introVideoTitle: "Welcome to the Wyndy Sitter Interview!",
  //       hideIntercom: true,
  //       hideHeader: true,
  //       onError: (data) => console.log("Error Message:", data.messageType),
  //       onFinish: async ({ video_id }) => {
  //         try {
  //           await submitInterviewVideo({ myInterviewVideoId: video_id });
  //           form.setFieldValue("myInterviewVideoId", video_id);
  //         } catch (error) {
  //           console.error(error);
  //         }
  //       },
  //     }}
  //     job={{
  //       questions: [
  //         {
  //           question: "Tell us about your paid babysitting experience.",
  //           partDuration: 90,
  //           numOfRetakes: -1,
  //         },
  //         {
  //           question:
  //             "What are you involved with in your free time outside of classes/work?",
  //           partDuration: 90,
  //           numOfRetakes: -1,
  //         },
  //         {
  //           question: "Why did you choose your major?",
  //           partDuration: 90,
  //           numOfRetakes: -1,
  //         },
  //         {
  //           question: "Why do you want to be a Wyndy?",
  //           partDuration: 90,
  //           numOfRetakes: -1,
  //         },
  //         {
  //           question: "How did you hear about Wyndy?",
  //           partDuration: 90,
  //           numOfRetakes: -1,
  //         },
  //       ],
  //     }}
  //   />
  // );

  return (
    <Container>
      <Text size="lg" fw={700}>
        Describe your sitter experience
      </Text>
      <FloatingLabelTextarea
        Header={
          <>
            <Text mt="md" fs={"19px"} fw={500}>
              Describe your paid or volunteer childcare experience
              {/*{" "}
              <Text size="md" span={true} c="red">
                *
              </Text>*/}
            </Text>
          </>
        }
        Component={Textarea}
        placeholder="Briefly describe your experience working with children..."
        labelType={"data-floating-bg"}
        mt="xs"
        label={""}
        minRows={8}
        {...form.getInputProps("paidSittingExperienceDetails")}
      />
      <CharactersCounter
        value={form?.values?.paidSittingExperienceDetails}
        requiredLen={100}
      />
      {/*<FloatingLabelInput*/}
      {/*  Header={*/}
      {/*    <Text mt="md" fs={"19px"} fw={500}>*/}
      {/*      Describe how you like to spend your time outside of work or classes{" "}*/}
      {/*      <Text size="md" span={true} c="red">*/}
      {/*        **/}
      {/*      </Text>*/}
      {/*    </Text>*/}
      {/*  }*/}
      {/*  Component={Textarea}*/}
      {/*  labelType={"data-floating-bg"}*/}
      {/*  mt="xs"*/}
      {/*  label="Share details here."*/}
      {/*  minRows={5}*/}
      {/*  {...form.getInputProps("freeTimeDetails")}*/}
      {/*/>*/}
      {/*<Text size="xs" align="left" weight="500">*/}
      {/*  [200 character minimum]*/}
      {/*</Text>*/}
      {/*<FloatingLabelInput*/}
      {/*  Header={*/}
      {/*    <Text mt="md" fs={"19px"} fw={500}>*/}
      {/*      Describe how you chose your major{" "}*/}
      {/*      <Text size="md" span={true} c="red">*/}
      {/*        **/}
      {/*      </Text>*/}
      {/*    </Text>*/}
      {/*  }*/}
      {/*  Component={Textarea}*/}
      {/*  labelType={"data-floating-bg"}*/}
      {/*  mt="xs"*/}
      {/*  label="Share details here."*/}
      {/*  minRows={5}*/}
      {/*  {...form.getInputProps("schoolMajorDetails")}*/}
      {/*/>*/}
      {/*<Text size="xs" align="left" weight="500">*/}
      {/*  [200 character minimum]*/}
      {/*</Text>*/}
      {/*<FloatingLabelInput*/}
      {/*  Header={*/}
      {/*    <Text mt="md" fs={"19px"} fw={500}>*/}
      {/*      How did you hear about Wyndy?{" "}*/}
      {/*      <Text size="md" span={true} c="red">*/}
      {/*        **/}
      {/*      </Text>*/}
      {/*    </Text>*/}
      {/*  }*/}
      {/*  Component={Textarea}*/}
      {/*  labelType={"data-floating-bg"}*/}
      {/*  mt="xs"*/}
      {/*  label="Share details here."*/}
      {/*  minRows={5}*/}
      {/*  {...form.getInputProps("hearAboutUsDetails")}*/}
      {/*/>*/}
      {/*<Text size="xs" align="left" weight="500">*/}
      {/*  [15 character minimum]*/}
      {/*</Text>*/}
      {/*<FloatingLabelInput*/}
      {/*  Header={*/}
      {/*    <Text mt="md" fw={500}>*/}
      {/*      Why do you want to become a Wyndy babysitter?{" "}*/}
      {/*      <Text size="md" span={true} c="red">*/}
      {/*        **/}
      {/*      </Text>*/}
      {/*    </Text>*/}
      {/*  }*/}
      {/*  Component={Textarea}*/}
      {/*  labelType={"data-floating-bg"}*/}
      {/*  mt="xs"*/}
      {/*  label="Share details here."*/}
      {/*  minRows={5}*/}
      {/*  {...form.getInputProps("whyDoYouWantToBeAWyndy")}*/}
      {/*/>*/}
      {/*<Text size="xs" align="left" weight="500">*/}
      {/*  [200 character minimum]*/}
      {/*</Text>*/}
      {/* <Button fullWidth rightIcon={<FaArrowRight />} onClick={goToNextStep}>
        Next step
      </Button> */}
      {/* <Text mt="md" size="sm" align="left" weight="500">
        Helpful things to know:
      </Text>
      <List mr="xl">
        <List.Item>
          <Text size="sm" align="left" weight="500">
            This video is not public – it will only be viewed by our application
            review team and will not be part of your profile.
          </Text>
        </List.Item>
        <List.Item>
          <Text size="sm" align="left" weight="500">
            All questions must be answered in one sitting.
          </Text>
        </List.Item>
        <List.Item>
          <Text size="sm" align="left" weight="500">
            Don’t worry, the entire video shouldn’t take more than 5 minutes.
          </Text>
        </List.Item>
      </List>
      <Group position="center" mt="xl" p="md">
        {interviewCompleted ? renderCompletedMessage() : renderWidget()}
        <div>
          {isDevelopment && (
            <Button
              variant="subtle"
              color="red"
              rightIcon={<FaArrowRight />}
              onClick={handleSkipInterview}
            >
              Skip
            </Button>
          )}
        </div>
      </Group> */}
    </Container>
  );
};

export default VideoInterview;
