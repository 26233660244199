import { gql } from "graphql-request";

import request, { gqlRequest } from "@/utils/request";

const API_VERSION = "v1";

export const loginService = async (fields) => {
  const mutation = gql`
    mutation SignIn($email: String!, $password: String!) {
      signIn(email: $email, password: $password) {
        token
        sitterApplication {
          id
          status
          qualified
          firstName
          lastName
        }
      }
    }
  `;

  const data = await gqlRequest.request(mutation, fields);
  return {
    token: data?.signIn?.token,
    ...data?.signIn?.sitterApplication,
  };
};

export const forgotPasswordService = async (email) => {
  const { data } = await request(API_VERSION).post(
    "sitters/password",
    { preformattedBody: true },
    { sitter: { email } }
  );

  return data;
};

export const resendConfirmationEmailService = async (email) => {
  const { data } = await request(API_VERSION).post(
    "sitters/confirmation",
    { preformattedBody: true },
    { sitter: { email } }
  );

  return data;
};
