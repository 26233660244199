import _ from "lodash";

export const isEmail = (input) => {
  const emailRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(input);
};

const cleanseValue = (value) => {
  if (value == null) {
    return "";
  }

  return value;
};

export const mobileNumber = (mobile) => {
  return mobile.replace(/\D/g, "");
};

//https://gist.github.com/felixjung/a00879103892af44524f/revisions
export const transformAttributes = (attributes, replaceThis, withThis) => {
  let newObj = _.cloneDeep(attributes);

  // Convert keys
  newObj = _.mapKeys(newObj, (value, key) =>
    key.replace(replaceThis, withThis)
  );

  // Recursively apply throughout object
  return _.mapValues(newObj, (value) => {
    if (_.isPlainObject(value)) {
      return transformAttributes(value, replaceThis, withThis);
    } else if (_.isArray(value)) {
      return _.map(value, (x) => {
        return _.isObject(x)
          ? transformAttributes(x, replaceThis, withThis)
          : x;
      });
    }
    return cleanseValue(value);
  });
};

export const transformResponseAttributes = (data) => {
  if (data.length === 0) return;

  const jsonData = JSON.parse(data);
  const transformedData = jsonData;

  if (jsonData.data && jsonData.data.attributes) {
    const convertedAttributes = transformAttributes(
      jsonData.data.attributes,
      /-/g,
      "_"
    );
    transformedData.attributes = convertedAttributes;
  }

  if (_.isArray(jsonData.data)) {
    const convertedData = transformAttributes(jsonData.data, /-/g, "_");
    transformedData.data = convertedData;
  }

  if (jsonData.included) {
    const convertedIncluded = transformAttributes(jsonData.included, /-/g, "_");
    transformedData.included = convertedIncluded;
  }

  if (jsonData.meta) {
    const convertedMeta = transformAttributes(jsonData.meta, /-/g, "_");
    transformedData.meta = convertedMeta;
  }

  return transformedData;
};

export const transformBody = (body) => ({
  data: {
    attributes: transformAttributes(body, /_/g, "-"),
  },
});
