import { useQuery } from "@tanstack/react-query";

import useAuth from "@/hooks/useAuth";
import { getPaymentIntent } from "@/services/paymentIntent";

export const usePaymentIntent = () => {
  const { auth } = useAuth();

  const { data, isLoading } = useQuery([], getPaymentIntent, {
    enabled: auth?.isAuth,
    refetchOnWindowFocus: false,
  });

  return {
    paymentIntent: data?.paymentIntent,
    membershipInterval: data?.membershipPrice?.recurring?.interval,
    isLoading: isLoading,
  };
};
