import { Group, Button, TextInput, Text, LoadingOverlay } from "@mantine/core";

import useResendConfirmationEmail from "@/hooks/useResendConfirmationEmail";
import { Container } from "@/components/Form/Container";
import { Form } from "@/components/Form/Form";
import { MobilePush } from "@/components/Common/MobilePush";
import { FloatingLabelInput } from "@/components/Form/FloatingLabelInput/FloatingLabelInput";

const ResendConfirmationEmail = () => {
  const { form, resendConfirmationEmail, loading } =
    useResendConfirmationEmail();

  return (
    <Container>
      <LoadingOverlay visible={loading} />
      <Text size="xl" fw={700}>
        Resend confirmation email
      </Text>
      <Form onSubmit={form.onSubmit(resendConfirmationEmail)}>
        <FloatingLabelInput
          Component={TextInput}
          withAsterisk
          mt="md"
          label="Email"
          placeholder="example@mail.com"
          {...form.getInputProps("email")}
        />

        <MobilePush />

        <Group position="center" mt="md">
          <Button fullWidth type="submit">
            Resend instructions
          </Button>
        </Group>
      </Form>
    </Container>
  );
};

export default ResendConfirmationEmail;
