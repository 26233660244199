import { gql } from "graphql-request";
import { showNotification } from "@mantine/notifications";

import { gqlRequest } from "@/utils/request";

export const getMarkets = async () => {
  const query = gql`
    query Markets {
      markets {
        id
        name
      }
    }
  `;

  try {
    const data = await gqlRequest.request(query);
    return data?.markets;
  } catch (err) {
    showNotification({
      color: "red",
      title: "Data fetch error",
      message: "Something wen't wrong, please refresh the page 😟",
    });
  }
  return {};
};
