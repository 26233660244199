import request from "@/utils/request";

const API_VERSION = "v1";

export const registrationService = async ({
  email,
  password,
  passwordConfirmation,
  privacyConsent,
  firstName = "Unknown",
  lastName = "Unknown",
}) => {
  const { data } = await request(API_VERSION).post(
    "sitters/registrations",
    { preformattedBody: true },
    {
      data: {
        attributes: {
          email,
          password,
          "password-confirmation": passwordConfirmation,
          "privacy-consent": privacyConsent,
          "first-name": firstName,
          "last-name": lastName,
        },
      },
    }
  );

  return data;
};
