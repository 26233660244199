import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";

import { optOut } from "@/services/application";

const schema = Yup.object().shape({
  optOutReason: Yup.string().required("You need to select a reason"),
  optOutNotes: Yup.string(),
});

const useOptOut = () => {
  const navigate = useNavigate();
  const form = useForm({
    clearInputErrorOnChange: true,
    initialValues: { optOutReason: "", optOutNotes: "" },
    validate: yupResolver(schema),
  });

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: optOut,
  });

  const handleOptOut = async ({ optOutReason, optOutNotes }) => {
    try {
      await mutateAsync({
        optOutReason,
        optOutNotes,
      });
      navigate("/opted-out");
    } catch (e) {
      showNotification({
        color: "red",
        title: "Error",
        message: "Something wen't wrong, please retry 😟",
      });
    }
  };

  return {
    form,
    optOut: handleOptOut,
    isLoading,
  };
};

export default useOptOut;
