import { useEffect } from "react";
import { Text, TextInput, Checkbox } from "@mantine/core";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { Container } from "@/components/Form/Container";
import { FloatingLabelInput } from "@/components/Form/FloatingLabelInput/FloatingLabelInput";

const LegalName = () => {
  const form = useApplicationFormContext();
  useProgressBar(9);

  useEffect(() => {
    if (form?.values.noMiddleName) {
      form.setFieldValue("legalMiddleName", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.values.noMiddleName]);

  return (
    <Container>
      <Text size="lg" fw={700}>
        Legal Name
      </Text>
      <Text size="md" mt="md" fw={500}>
        Your legal name and information are needed for payment, identity
        verification, and a background check
      </Text>
      <FloatingLabelInput
        Component={TextInput}
        withAsterisk
        mt="md"
        placeholder="First Name"
        label="Legal First Name"
        {...form.getInputProps("legalFirstName")}
      />
      <FloatingLabelInput
        Component={TextInput}
        withAsterisk
        disabled={form?.values.noMiddleName}
        mt="md"
        placeholder="Middle Name"
        label="Legal Middle Name"
        {...form.getInputProps("legalMiddleName")}
        value={form?.values.legalMiddleName}
      />
      <Checkbox
        mt="md"
        label="No Middle Name"
        checked={form.values.noMiddleName}
        {...form.getInputProps("noMiddleName")}
      />
      <FloatingLabelInput
        Component={TextInput}
        withAsterisk
        mt="md"
        placeholder="Last Name"
        label="Legal Last Name"
        {...form.getInputProps("legalLastName")}
      />
    </Container>
  );
};

export default LegalName;
