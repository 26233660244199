import { gql } from "graphql-request";
import { showNotification } from "@mantine/notifications";

import { gqlRequest } from "@/utils/request";

export const getColleges = async (title) => {
  if (!title.length) {
    return [];
  }

  const query = gql`
    query ($title: String) {
      nearestColleges(title: $title) {
        nodes {
          id
          title
        }
      }
    }
  `;

  try {
    const data = await gqlRequest.request(query, { title });
    return data?.nearestColleges?.nodes;
  } catch (err) {
    showNotification({
      color: "red",
      title: "Data fetch error",
      message: "Something wen't wrong, please refresh the page 😟",
    });
  }
  return {};
};

// export const getColleges = async (state) => {
//   const query = gql`
//     query Colleges($state: String!) {
//       colleges(state: $state) {
//         value: id
//         label: title
//       }
//     }
//   `;
//
//   try {
//     const data = await gqlRequest.request(query, { state });
//     return data?.colleges;
//   } catch (err) {
//     showNotification({
//       color: "red",
//       title: "Data fetch error",
//       message: "Something wen't wrong, please refresh the page 😟",
//     });
//   }
//   return {};
// };
