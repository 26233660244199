import { useEffect, useState } from "react";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { useMutation } from "@tanstack/react-query";

import useAuth from "@/hooks/useAuth";
import { loginService } from "@/services/auth";
import formPreValidation from "@/utils/formPreValidation";

const schema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Invalid email"),
  password: Yup.string().required("Password is required"),
});

const preValidationSchema = formPreValidation.getScheme(schema);

const useLogin = () => {
  const { auth, setAuth } = useAuth();
  const { isLoading, mutate } = useMutation({
    mutationFn: loginService,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const form = useForm({
    clearInputErrorOnChange: true,
    validate: yupResolver(schema),
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      terms: false,
    },
  });

  useEffect(() => {
    if (auth.isAuth) {
      // We need to reload the page so everything is set properly
      window.location.assign("/dashboard");
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    preValidationSchema.isValid(form.values).then((isValid) => {
      if (isValid) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values]);

  const handleLogin = ({ email, password }) =>
    mutate(
      {
        email,
        password,
      },
      {
        onSuccess: (data) => {
          if (data && !data?.errors && data.token) {
            setAuth(data);
          } else {
            showNotification({
              color: "red",
              title: "Login failed",
              message:
                "Something wen't wrong with the login, your credentials might be incorrect 😟",
            });
          }
        },
        onError: () => {
          showNotification({
            color: "red",
            title: "Login failed",
            message:
              "Something wen't wrong with the login, your credentials might be incorrect 😟",
          });
        },
      }
    );

  return {
    form,
    login: handleLogin,
    loading: isLoading,
    isButtonDisabled: isButtonDisabled,
  };
};

export default useLogin;
