import { useMutation } from "@tanstack/react-query";
import { setApplicationData } from "@/services/application";

const useSaveFormData = () => {
  const { data, error, isLoading, mutateAsync } =
    useMutation(setApplicationData);
  return { data, error, isLoadingSave: isLoading, saveData: mutateAsync };
};

export default useSaveFormData;
