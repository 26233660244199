import { useEffect, useState } from "react";
import { Text } from "@mantine/core";

import inputClasses from "@/components/Form/FloatingLabelInput/FloatingLabelInput.module.css";
import selectClasses from "@/components/Form/FloatingSelect/FloatingSelect.module.css";

export function FloatingSelect({
  Component,
  floatOnFocus,
  forwardRef,
  mt,
  ...props
}) {
  const classes = { ...inputClasses, ...selectClasses };
  const [floating, setFloating] = useState(false);
  const [forceFloating, setForceFloating] = useState(false);
  const value =
    typeof props?.value === "boolean" ? props?.value : props?.value || "";
  const error = props?.error || "";
  const labelHasValue =
    typeof value === "boolean" ||
    value.trim().length !== 0 ||
    error.trim().length !== 0;

  useEffect(() => {
    setFloating(labelHasValue || forceFloating);
  }, [value, error, labelHasValue, forceFloating]);

  return (
    <>
      {props.label && (
        <Text size="md" mt={mt} fw={600}>
          {props.label}
          {props.withAsterisk && (
            <Text size="md" span={true} c="red">
              {" "}
              *
            </Text>
          )}
        </Text>
      )}
      <Component
        classNames={classes}
        data-floating={floating}
        labelProps={{ "data-floating": floating, "data-error": !!props.error }}
        ref={forwardRef}
        {...props}
        onFocus={() => {
          if (typeof props?.onFocus === "function") {
            props.onFocus();
          }
          // Errors cheat fix. If the input is focused, the error label will be hidden
          if (typeof props?.onChange === "function") {
            props.onChange(props?.value);
          }
          if (floatOnFocus) {
            setForceFloating(true);
          }
        }}
        onBlur={() => {
          if (typeof props?.onBlur === "function") {
            props.onBlur();
          }
          setFloating(labelHasValue);
          setForceFloating(false);
        }}
        label={props.error || props.placeholder}
        error={""}
        placeholder={""}
        withAsterisk={!props.label && !props.error && props.withAsterisk}
        mt={!props.label && mt ? mt : 0}
      />
    </>
  );
}
