import { Text, ScrollArea, Flex, Group, Paper, Checkbox } from "@mantine/core";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { Container } from "@/components/Form/Container";

const BackgroundCheck = () => {
  const form = useApplicationFormContext();
  useProgressBar(13);

  return (
    <Container>
      <Text size="lg" fw={700}>
        Background Check Disclosure
      </Text>
      <Paper mt="md" shadow="sm" radius="sm" withBorder>
        <Group p="xs">
          <ScrollArea style={{ height: 250 }} type="auto" p="0">
            <Flex
              gap="md"
              justify="flex-start"
              align="flex-start"
              direction="row"
              wrap="wrap"
            >
              <Text
                style={{ fontSize: "12px", overflowWrap: "anywhere" }}
                color="placeholder.0"
              >
                <h3 class="my-4" style={{ marginTop: 0 }}>
                  Disclosure Regarding Background Investigation
                </h3>
                <div class="mb-8 bg-grey-lighter p-4">
                  <p class="mb-4">
                    Wyndy (“the Company”) may obtain information about you from
                    a third party consumer reporting agency for employment
                    purposes. Thus, you may be the subject of a “consumer
                    report” and/or an “investigative consumer report” which may
                    include information about your character, general
                    reputation, personal characteristics, and/or mode of living,
                    and which can involve personal interviews with sources such
                    as your neighbors, friends, or associates. These reports may
                    contain information regarding your criminal history, social
                    security verification, motor vehicle records (“driving
                    records”), verification of your education or employment
                    history, or other background checks.
                  </p>
                  <p class="mb-4">
                    You have the right, upon written request made within a
                    reasonable time, to request whether a consumer report has
                    been run about you, and disclosure of the nature and scope
                    of any investigative consumer report and to request a copy
                    of your report. Please be advised that the nature and scope
                    of the most common form of investigative consumer report is
                    an employment history or verification. These searches will
                    be conducted by Checkr, Inc., 2505 Mariposa St. San
                    Francisco, CA 94110 | 844-824-3247{" "}
                    <a href="https://checkr.com">checkr.com</a>
                    <p></p>The scope of this disclosure is all-encompassing,
                    however, allowing the Company to obtain from any outside
                    organization all manner of consumer reports throughout the
                    course of your employment to the extent permitted by law.
                  </p>
                </div>
              </Text>
            </Flex>
          </ScrollArea>
        </Group>
      </Paper>
      <Checkbox
        mt="md"
        label="I acknowledge receipt of the Disclosure Regarding Background
        Investigation and certify that I have read and understand this document"
        checked={form.values.disclosureAgreement}
        className="align-top"
        {...form.getInputProps("disclosureAgreement")}
      />
    </Container>
  );
};

export default BackgroundCheck;
