import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";

import { getUserData } from "@/services/application";

const LoginByTokenPage = () => {
  const navigate = useNavigate();
  const { setAuth, logout } = useAuth();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const authenticationToken = queryParams.get("authentication_token");

    if (!authenticationToken) {
      navigate("/login");
      return;
    }

    setAuth({ token: authenticationToken });

    setTimeout(async () => {
      const data = await getUserData();

      if (!data?.sitterId) {
        logout();
        navigate("/login");
        return;
      }

      setAuth({ ...data, token: authenticationToken });
      navigate("/dashboard");
    }, 100);
  }, []);

  return <div />;
};

export default LoginByTokenPage;
