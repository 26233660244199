import * as Yup from "yup";

// Applications steps
import NameAndReferral from "@/pages/Application/pages/NameAndReferral/NameAndReferral";
import PhoneNumber from "@/pages/Application/pages/PhoneNumber/PhoneNumber";
// import Market from "@/pages/Application/pages/Market/Market";
import BasicQualifications from "@/pages/Application/pages/BasicQualifications/BasicQualifications";
import ProfilePhoto from "@/pages/Application/pages/ProfilePhoto/ProfilePhoto";
import VideoInterview from "@/pages/Application/pages/VideoInterview/VideoInterview";
import LegalName from "@/pages/Application/pages/LegalName/LegalName";
import CurrentAddress from "@/pages/Application/pages/CurrentAddress/CurrentAddress";
import Identity from "@/pages/Application/pages/Identity/Identity";
import FCRADisclosure from "@/pages/Application/pages/FCRADisclosure/FCRADisclosure";
import BackgroundCheck from "@/pages/Application/pages/BackgroundCheck/BackgroundCheck";
import BackgroundCheckAuthorization from "@/pages/Application/pages/BackgroundCheckAuthorization/BackgroundCheckAuthorization";
import Review from "@/pages/Application/pages/Review/Review";
import SubscriptionPayment from "@/pages/Application/pages/SubscriptionPayment/SubscriptionPayment";
import EducationDetails from "@/pages/Application/pages/EducationDetails/EducationDetails";
import WhyJoin from "@/pages/Application/pages/WhyJoin/WhyJoin";
import ZipCode from "@/pages/Application/pages/ZipCode/ZipCode";

let applicationFlow = [
  {
    path: "name",
    element: <NameAndReferral />,
    validation: Yup.object()
      .nullable()
      .shape({
        firstName: Yup.string()
          .nullable()
          .trim()
          .required("First Name is required"),
        lastName: Yup.string()
          .nullable()
          .trim()
          .required("Last Name is required"),
        // hearAboutUs: Yup.string().nullable().required("Referral is required"),
      }),
    skipStep: (form, auth) => {
      return (
        auth.firstName &&
        auth.lastName &&
        auth.firstName !== "Unknown" &&
        auth.lastName !== "Unknown"
      );
    },
  },
  {
    path: "phone-number",
    element: <PhoneNumber />,
    validation: Yup.object()
      .nullable()
      .shape({
        phone: Yup.string()
          .nullable()
          .required("Phone Number is required")
          .test(
            "len",
            "Phone number must have 11 numbers",
            (val) => val?.replace(/(-|_)/g, "").length === 10
          ),
      }),
    backButtonDisabled: true,
  },
  {
    path: "zip-code",
    element: <ZipCode />,
    validation: Yup.object()
      .nullable()
      .shape({
        zip: Yup.string()
          .nullable()
          .required("Zip code is required")
          .test(
            "len",
            "Zip code must have 5 numbers",
            (val) => val?.replace(/(_)/g, "").length === 5
          ),
      }),
    skipStep: (form) => {
      return !!form.values.zipPrefilled;
    },
  },
  // {
  //   path: "market",
  //   element: <Market />,
  //   validation: Yup.object().nullable().shape({
  //     marketId: Yup.string(),
  //   }),
  // },
  {
    path: "basic-qualifications",
    element: <BasicQualifications />,
    validation: Yup.object()
      .nullable()
      .shape({
        dob: Yup.string()
          .nullable()
          .required("Birth date is required")
          .test(
            "len",
            "Please enter a correct birth date",
            (val) => val?.replace(/(\/|_)/g, "").length === 8
          ),
        reliableTransportation: Yup.string()
          .nullable()
          .required("Reliable transportation field is required")
          .test(
            "reliable-transportation",
            "All Wyndy sitters must have access to reliable transportation",
            function (value) {
              return !value || value === "true";
            }
          ),
        paidSittingExperience: Yup.string()
          .nullable()
          .required("Babysitting experience is required")
          .test(
            "reliable-transportation",
            "All Wyndy sitters must have access to reliable transportation",
            function (value) {
              return !value || value !== "none";
            }
          ),
      }),
  },
  {
    path: "education-details",
    element: <EducationDetails />,
    validation: Yup.object()
      .nullable()
      .shape({
        collegeLevel: Yup.string()
          .nullable()
          .required("College experience is required"),
        collegeId: Yup.string().required("College is required"),
      }),
  },
  {
    path: "profile-photo",
    element: <ProfilePhoto />,
    validation: Yup.object()
      .nullable()
      .shape({
        imageData: Yup.mixed().nullable().required("Profile Photo is required"),
      }),
  },
  {
    path: "video-interview",
    element: <VideoInterview />,
    validation: Yup.object()
      .nullable()
      .shape({
        paidSittingExperienceDetails: Yup.string()
          .nullable()
          .trim()
          .required("Answer is required")
          .test(
            "pre-validation-len",
            "100 character minimum",
            (val) => val?.length >= 100
          ),
        // freeTimeDetails: Yup.string()
        //   .nullable()
        //   .trim()
        //   .required("Answer is required")
        //   .test("len", "200 character minimum", (val) => val?.length > 200),
        // schoolMajorDetails: Yup.string()
        //   .nullable()
        //   .trim()
        //   .required("Answer is required")
        //   .test("len", "200 character minimum", (val) => val?.length > 200),
        // hearAboutUsDetails: Yup.string()
        //   .nullable()
        //   .trim()
        //   .required("Answer is required")
        //   .test("len", "15 character minimum", (val) => val?.length > 15),
        // whyDoYouWantToBeAWyndy: Yup.string()
        //   .nullable()
        //   .trim()
        //   .required("Answer is required")
        //   .test("len", "200 character minimum", (val) => val?.length > 200),
      }),
  },
  {
    path: "why-do-you-want-to-join",
    element: <WhyJoin />,
    validation: Yup.object()
      .nullable()
      .shape({
        whyDoYouWantToBeAWyndy: Yup.string()
          .nullable()
          .trim()
          .required("Answer is required")
          .test(
            "pre-validation-len",
            "100 character minimum",
            (val) => val?.length >= 100
          ),
      }),
  },
  {
    path: "subscription-payment",
    element: <SubscriptionPayment />,
    hideButtons: true,
    skipStep: (form) => {
      return form?.values?.paymentIntent?.status === "requires_capture";
    },
  },
  {
    path: "legal-name",
    element: <LegalName />,
    skip: true,
    validation: Yup.object()
      .nullable()
      .shape({
        legalFirstName: Yup.string()
          .nullable()
          .required("Legal First Name is required"),
        legalMiddleName: Yup.string()
          .nullable()
          .test(
            "legal-middle-name",
            "Legal Middle Name is required",
            function (value) {
              return value || this.parent.noMiddleName === true;
            }
          ),
        legalLastName: Yup.string()
          .nullable()
          .required("Legal Last Name is required"),
      }),
  },
  {
    path: "current-address",
    element: <CurrentAddress />,
    skip: true,
    validation: Yup.object()
      .nullable()
      .shape({
        address1: Yup.string()
          .nullable()
          .required("Street address is required"),
        city: Yup.string().nullable().required("City is required"),
        state: Yup.string().nullable().required("State is required"),
        zip: Yup.string()
          .nullable()
          .required("Zip code is required")
          .test(
            "len",
            "Zip code must have 5 numbers",
            (val) => val?.length === 5
          ),
      }),
  },
  {
    path: "identity",
    element: <Identity />,
    skip: true,
    validation: Yup.object()
      .nullable()
      .shape({
        ssn: Yup.string()
          .nullable()
          .required("Social Security Number is required")
          .test(
            "len",
            "Social Security Number must have 9 numbers",
            (val) => val?.replace(/(-|_)/g, "").length === 9
          ),
      }),
  },
  {
    path: "fcra-acknowledgement",
    element: <FCRADisclosure />,
    canEndApplication: false,
    validation: Yup.object()
      .nullable()
      .shape({
        fcraAgreement: Yup.boolean()
          .nullable()
          .oneOf([true], "Please acknowledge in order to continue"),
      }),
  },
  {
    path: "background-check",
    element: <BackgroundCheck />,
    canEndApplication: false,
    validation: Yup.object()
      .nullable()
      .shape({
        disclosureAgreement: Yup.boolean()
          .nullable()
          .oneOf([true], "Please acknowledge in order to continue"),
      }),
  },
  {
    path: "background-check-authorization",
    element: <BackgroundCheckAuthorization />,
    canEndApplication: false,
    validation: Yup.object()
      .nullable()
      .shape({
        signature: Yup.string()
          .nullable()
          .required("You need to put in your signature"),
      }),
  },
  {
    path: "review",
    element: <Review />,
    validation: Yup.object()
      .nullable()
      .shape({
        legalFirstName: Yup.string()
          .nullable()
          .required("Legal First Name is required"),
        legalLastName: Yup.string()
          .nullable()
          .required("Legal Last Name is required"),
        dob: Yup.string()
          .nullable()
          .required("Birth date is required")
          .test(
            "len",
            "Please enter a correct birth date",
            (val) => val?.replace(/(\/|_)/g, "").length === 8
          ),
        phone: Yup.string()
          .nullable()
          .required("Phone Number is required")
          .test(
            "len",
            "Phone number must have 11 numbers",
            (val) => val?.replace(/(-|_)/g, "").length === 10
          ),
        ssn: Yup.string()
          .nullable()
          .required("Social Security Number is required")
          .test(
            "len",
            "Social Security Number must have 9 numbers",
            (val) => val?.replace(/(-|_)/g, "").length === 9
          ),
      }),
  },
];

export default applicationFlow;
