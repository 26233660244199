import { useEffect } from "react";
import {
  PasswordInput,
  Group,
  Button,
  TextInput,
  Divider,
  Anchor,
  Text,
  LoadingOverlay,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";

import useLogin from "@/hooks/useLogin";
import useAuth from "@/hooks/useAuth";
import { Container } from "@/components/Form/Container";
import { Form } from "@/components/Form/Form";
import { MobilePush } from "@/components/Common/MobilePush";
import { FloatingLabelInput } from "@/components/Form/FloatingLabelInput/FloatingLabelInput";

const LoginPage = () => {
  const { form, login, loading, isButtonDisabled } = useLogin();
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuth) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <LoadingOverlay visible={loading} />
      <Text size="xl" weight="700">
        Wyndy Sitter Sign In
      </Text>
      <Form onSubmit={form.onSubmit(login)}>
        <FloatingLabelInput
          Component={TextInput}
          withAsterisk
          mt="md"
          label="Email"
          placeholder="example@mail.com"
          {...form.getInputProps("email")}
        />
        <FloatingLabelInput
          Component={PasswordInput}
          withAsterisk
          mt="md"
          label="Password"
          placeholder="Password"
          autoComplete="new-password"
          {...form.getInputProps("password")}
        />

        <MobilePush />

        <Group position="center" mt="md">
          <Button fullWidth type="submit" disabled={isButtonDisabled}>
            Login
          </Button>
          <Group position="center">
            <Anchor size="sm" href="/forgot-password">
              Forgot password
            </Anchor>
          </Group>
        </Group>
        <Divider
          mt="lg"
          my="xs"
          label="No account yet ?"
          labelPosition="center"
        />
        <Group position="center">
          <Anchor href="/register">👉 Sign up</Anchor>
        </Group>
      </Form>
    </Container>
  );
};

export default LoginPage;
