import { TextInput, Text } from "@mantine/core";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import { FloatingLabelInput } from "@/components/Form/FloatingLabelInput/FloatingLabelInput";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { Container } from "@/components/Form/Container";
// import { FloatingSelect } from "@/components/Form/FloatingSelect/FloatingSelect";

const NameAndReferral = () => {
  const form = useApplicationFormContext();
  useProgressBar(1);

  return (
    <Container>
      <Text size="lg" fw={700}>
        Tell us about yourself
      </Text>
      <FloatingLabelInput
        Component={TextInput}
        withAsterisk
        mt="md"
        label="First name"
        {...form.getInputProps("firstName")}
      />
      <FloatingLabelInput
        Component={TextInput}
        withAsterisk
        mt="md"
        label="Last name"
        {...form.getInputProps("lastName")}
      />
      {/*<FloatingSelect*/}
      {/*  Component={Select}*/}
      {/*  withAsterisk*/}
      {/*  mt="md"*/}
      {/*  label="How did you hear about Wyndy?"*/}
      {/*  placeholder="Referral source"*/}
      {/*  data={[*/}
      {/*    { value: "friend", label: "Friend" },*/}
      {/*    { value: "flier_or_poster", label: "Flier or Poster" },*/}
      {/*    { value: "campus_organization", label: "Campus Organization" },*/}
      {/*    { value: "social_media", label: "Social Media" },*/}
      {/*    { value: "google", label: "Search Engine" },*/}
      {/*    { value: "online_article", label: "Online Article" },*/}
      {/*    { value: "campus_ambassador", label: "Campus Ambassador" },*/}
      {/*    { value: "career_center", label: "Career Center" },*/}
      {/*    { value: "job_board", label: "Job Board" },*/}
      {/*    { value: "direct_mail", label: "Direct Mail" },*/}
      {/*    { value: "billboard_campus_tv", label: "Billboard/Campus TV" },*/}
      {/*    { value: "other", label: "Other" },*/}
      {/*  ]}*/}
      {/*  {...form.getInputProps("hearAboutUs")}*/}
      {/*/>*/}
    </Container>
  );
};

export default NameAndReferral;
