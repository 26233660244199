import { Text, Group, Button, Anchor } from "@mantine/core";

const OptOutMessage = () => {
  return (
    <div>
      <Text size="xl" fw={700}>
        Thanks for your interest in Wyndy!
      </Text>
      <Text mt="md" size="md" weight="500">
        Unfortunately, it looks like you opted out of our sitter application
        process
      </Text>
      <Group position="center" mt="xl">
        <Text size="xs" align="left" weight="500">
          If you like to get back in the application process, please contact our
          support team
        </Text>

        <Anchor
          href="mailto:support@wyndy.com"
          pt="md"
          display="block"
          underline={false}
          style={{ width: "100%" }}
        >
          <Button fullWidth>Contact Support</Button>
        </Anchor>
      </Group>
    </div>
  );
};

export default OptOutMessage;
