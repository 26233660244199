import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import { MantineProvider, Global } from "@mantine/core";
import { QueryClientProvider } from "@tanstack/react-query";

import queryClient from "@/services/client";
import defaultTheme from "@/themes/default";
import App from "@/App";
import reportWebVitals from "@/reportWebVitals";
import globalStyles from "@/globalStyles";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <MantineProvider withGlobalStyles withNormalizeCSS theme={defaultTheme}>
          <Global styles={globalStyles} />
          <App />
        </MantineProvider>
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
