import { createBrowserRouter } from "react-router-dom";

import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";

// PAGES
import Root from "@/pages/Root";
import ErrorPage from "@/pages/ErrorPage";
import Login from "@/pages/Login/LoginPage";
import LoginByTokenPage from "@/pages/LoginByToken/LoginByTokenPage";
import ForgotPassword from "@/pages/ForgotPassword/ForgotPassword";
import ResendConfirmationEmail from "@/pages/ResendConfirmationEmail/ResendConfirmationEmail";
import Introduction from "@/pages/Introduction/Introduction";
import Dashboard from "@/pages/Dashboard/Dashboard";
import AddPaymentMethod from "@/pages/AddPaymentMethod/AddPaymentMethod";
import Profile from "@/pages/Profile/Profile";
import SubmissionProfile from "@/pages/SubmissionProfile/SubmissionProfile";
import Register from "@/pages/Register/RegisterPage";
import Application from "@/pages/Application/ApplicationPage";
import OptOutConfirmation from "@/pages/Application/pages/OptOutConfirmation/OptOutConfirmation";
import OptOutForm from "@/pages/Application/pages/OptOutForm/OptOutForm";
import MarketUnavailable from "@/pages/Application/pages/MarketUnavailable/MarketUnavailable";
import BasicQualificationsDenied from "@/pages/BasicQualificationsDenied/BasicQualificationsDenied";
import OptOutMessage from "@/pages/OptOutMessage/OptOutMessage";

import ApplicationFlow from "@/pages/Application/flow";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "login-by-token",
        element: <LoginByTokenPage />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "resend-confirmation-email",
        element: <ResendConfirmationEmail />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "dashboard",
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "/add-payment-method",
        element: (
          <ProtectedRoute>
            <AddPaymentMethod />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        ),
      },
      {
        path: "submission-profile",
        element: (
          <ProtectedRoute>
            <SubmissionProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: "introduction",
        element: (
          <ProtectedRoute>
            <Introduction />
          </ProtectedRoute>
        ),
      },
      {
        path: "application",
        element: (
          <ProtectedRoute>
            <Application disableSkipButton />
          </ProtectedRoute>
        ),
        children: ApplicationFlow,
      },
      {
        path: "application/opt-out",
        element: (
          <ProtectedRoute>
            <OptOutConfirmation />
          </ProtectedRoute>
        ),
      },
      {
        path: "application/opt-out-form",
        element: (
          <ProtectedRoute>
            <OptOutForm />
          </ProtectedRoute>
        ),
      },
      {
        path: "application/market-unavailable",
        element: (
          <ProtectedRoute>
            <MarketUnavailable />
          </ProtectedRoute>
        ),
      },
      {
        path: "basic-qualifications-denied",
        element: (
          <ProtectedRoute>
            <BasicQualificationsDenied />
          </ProtectedRoute>
        ),
      },
      {
        path: "opted-out",
        element: (
          <ProtectedRoute>
            <OptOutMessage />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

export default router;
