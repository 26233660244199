import repeat from "lodash/repeat";

import variables from "@/config/variables";
import { TextInput } from "@mantine/core";
import InputMask from "react-input-mask";

export function DateOfBirthInput({ Component, ...props }) {
  const replaceMaskWithLetter = (value, letter, length) => {
    value = value?.replace(/[^0-9]/g, "");
    return value + repeat(letter, length - value.length);
  };

  const fillInMaskWithLetters = (value) => {
    if (!value) {
      return "";
    }

    const [month, day, year] = value.split("/");

    return [
      replaceMaskWithLetter(month, "m", 2),
      replaceMaskWithLetter(day, "d", 2),
      replaceMaskWithLetter(year, "y", 4),
    ].join("/");
  };

  const findFirstPlaceholderIndex = (value) => {
    const placeholderPositions = [
      value.indexOf("m"),
      value.indexOf("d"),
      value.indexOf("y"),
    ].filter((position) => position >= 0);

    if (placeholderPositions.length === 0) {
      return null;
    }

    return Math.min(...placeholderPositions);
  };

  const beforeMaskedValueChange = (newState) => {
    const value = fillInMaskWithLetters(newState.value);
    let selection = newState.selection;

    if (selection && selection.length === 1) {
      const index =
        findFirstPlaceholderIndex(value) || Math.max(0, value.length - 1);

      selection = {
        start: index,
        end: index,
        length: 1,
      };
    }

    return { value, selection };
  };

  return (
    <>
      <Component
        {...props}
        floatOnFocus
        mask="nm/ed/zyyy"
        alwaysShowMask={false}
        beforeMaskedValueChange={beforeMaskedValueChange}
        formatChars={variables.dateFormatChars}
        Component={TextInput}
        component={InputMask}
      />
    </>
  );
}
