import {
  Group,
  Button,
  TextInput,
  Text,
  LoadingOverlay,
  Anchor,
} from "@mantine/core";

import useForgotPassword from "@/hooks/useForgotPassword";
import { Container } from "@/components/Form/Container";
import { MobilePush } from "@/components/Common/MobilePush";
import { FloatingLabelInput } from "@/components/Form/FloatingLabelInput/FloatingLabelInput";
import { Form } from "@/components/Form/Form";

const ForgotPassword = () => {
  const { form, resetPassword, loading } = useForgotPassword();

  return (
    <Container>
      <LoadingOverlay visible={loading} />
      <Text size="xl" fw={700}>
        Reset your password
      </Text>
      <Form onSubmit={form.onSubmit(resetPassword)}>
        <FloatingLabelInput
          Component={TextInput}
          withAsterisk
          mt="md"
          label="Email"
          placeholder="example@mail.com"
          {...form.getInputProps("email")}
        />

        <MobilePush />

        <Group position="center" mt="md">
          <Button fullWidth type="submit">
            Send instructions
          </Button>
        </Group>
        <Group position="center" mt="md">
          <Anchor size="sm" href="/resend-confirmation-email">
            Resend confirmation email
          </Anchor>
        </Group>
      </Form>
    </Container>
  );
};

export default ForgotPassword;
