import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  mobilePush: {
    flexGrow: 0,
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      flexGrow: 1,
    },
  },
}));

export const MobilePush = () => {
  const { classes } = useStyles();
  return <div className={classes.mobilePush} />;
};
