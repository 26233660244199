import { useEffect, useRef } from "react";
import { Carousel } from "@mantine/carousel";
import { createStyles, Text, Image, Flex, Grid } from "@mantine/core";

import { useApplicationStatistics } from "@/hooks/useApplicationStatisitcs";
import card1_bg from "@/assets/images/card1_bg.svg";
import card2_bg from "@/assets/images/card2_bg.svg";
import card3_bg from "@/assets/images/card3_bg.svg";
import card4_bg from "@/assets/images/card4_bg.svg";
import card5_bg from "@/assets/images/card5_bg.svg";
import megaphone from "@/assets/images/megaphone-48.svg";
import envelope from "@/assets/images/job-email-48.svg";
import thumbsUp from "@/assets/images/thumbs-up-48.svg";
import popper from "@/assets/images/popper-48.svg";
import heart from "@/assets/images/heart-48.svg";

export const InfoCards = () => {
  const { classes } = useStyles();

  const { statistics } = useApplicationStatistics();

  const autoplayRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    startAutoplay();

    return () => {
      stopAutoplay();
    };
  }, []);

  const startAutoplay = () => {
    intervalRef.current = setInterval(() => {
      if (autoplayRef.current) {
        autoplayRef.current.scrollNext();
      }
    }, 5000);
  };

  const stopAutoplay = () => {
    clearInterval(intervalRef.current);
  };

  return (
    <Carousel
      withControls={false}
      withIndicators
      loop
      mx="auto"
      slideGap="xs"
      height={109}
      classNames={{
        slide: classes.slide,
        indicators: classes.indicators,
        indicator: classes.indicator,
      }}
      getEmblaApi={(embla) => (autoplayRef.current = embla)}
      onMouseEnter={stopAutoplay}
      onMouseLeave={startAutoplay}
    >
      <Carousel.Slide>
        <Flex
          className={classes.slideContainer}
          sx={{
            backgroundColor: "#ebf6fe",
            backgroundImage: `url(${card1_bg})`,
          }}
        >
          <Grid align="center">
            <Grid.Col span="content">
              <Image width={48} src={megaphone} />
            </Grid.Col>
            <Grid.Col span="auto">
              <Text size="sm" fw={500}>
                <Text component="span" fw={700}>
                  {statistics?.completedJobsCount?.toLocaleString("en-US")}+
                </Text>{" "}
                babysitting jobs have been completed on Wyndy since 2017
              </Text>
            </Grid.Col>
          </Grid>
        </Flex>
      </Carousel.Slide>
      <Carousel.Slide>
        <Flex
          className={classes.slideContainer}
          sx={{
            backgroundColor: "rgba(234, 144, 167, 0.1)",
            backgroundImage: `url(${card2_bg})`,
          }}
        >
          <Grid align="center">
            <Grid.Col span="content">
              <Image width={48} src={envelope} />
            </Grid.Col>
            <Grid.Col span="auto">
              <Text size="sm" fw={500}>
                Sitters can access{" "}
                <Text component="span" fw={700}>
                  1,000+
                </Text>{" "}
                new childcare jobs each week
              </Text>
            </Grid.Col>
          </Grid>
        </Flex>
      </Carousel.Slide>
      <Carousel.Slide>
        <Flex
          className={classes.slideContainer}
          sx={{
            backgroundColor: "rgba(94, 198, 201, 0.1)",
            backgroundImage: `url(${card3_bg})`,
          }}
        >
          <Grid align="center">
            <Grid.Col span="content">
              <Image width={48} src={thumbsUp} />
            </Grid.Col>
            <Grid.Col span="auto">
              <Text size="sm" fw={500}>
                <Text component="span" fw={700}>
                  25,000+
                </Text>{" "}
                babysitters trust Wyndy to help find jobs
              </Text>
            </Grid.Col>
          </Grid>
        </Flex>
      </Carousel.Slide>
      <Carousel.Slide>
        <Flex
          className={classes.slideContainer}
          sx={{
            backgroundColor: "rgba(167, 144, 234, 0.1)",
            backgroundImage: `url(${card4_bg})`,
          }}
        >
          <Grid align="center">
            <Grid.Col span="content">
              <Image width={48} src={popper} />
            </Grid.Col>
            <Grid.Col span="auto">
              <Text size="sm" fw={500}>
                Wyndy babysitters earn{" "}
                <Text component="span" fw={700}>
                  $19/hr
                </Text>{" "}
                on average
              </Text>
            </Grid.Col>
          </Grid>
        </Flex>
      </Carousel.Slide>
      <Carousel.Slide>
        <Flex
          className={classes.slideContainer}
          sx={{
            backgroundColor: "rgba(253, 203, 156, 0.1)",
            backgroundImage: `url(${card5_bg})`,
          }}
        >
          <Grid align="center">
            <Grid.Col span="content">
              <Image width={48} src={heart} />
            </Grid.Col>
            <Grid.Col span="auto">
              <Text size="sm" fw={500}>
                When you finish a babysitting job, get paid{" "}
                <Text component="span" fw={700}>
                  tips
                </Text>{" "}
                on the Wyndy app
              </Text>
            </Grid.Col>
          </Grid>
        </Flex>
      </Carousel.Slide>
    </Carousel>
  );
};

const useStyles = createStyles((theme) => ({
  slide: {
    userSelect: "none",
  },
  indicators: {
    alignItems: "center",
    bottom: 0,
    marginTop: 7,
    gap: 5,
  },
  indicator: {
    width: 6,
    height: 6,
    transition: "none",
    backgroundColor: "rgba(0, 0, 0, 0.15)",

    "&[data-active]": {
      width: 8,
      height: 8,
      paddingVertical: 0,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  },
  slideContainer: {
    borderRadius: 12,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 94,
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: "center",
    justifyContent: "center",
  },
}));
