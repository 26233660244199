import { Text, TextInput, Select } from "@mantine/core";
import states from "states-us";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { Container } from "@/components/Form/Container";
import { FloatingLabelInput } from "@/components/Form/FloatingLabelInput/FloatingLabelInput";
import { FloatingSelect } from "@/components/Form/FloatingSelect/FloatingSelect";
import { useRef } from "react";

const CurrentAddress = () => {
  const form = useApplicationFormContext();
  useProgressBar(10);
  const stateInputRef = useRef(null);

  return (
    <Container>
      <Text size="lg" fw={700}>
        Current Physical Address
      </Text>
      <Text size="md" mt="md" fw={500}>
        Your physical address is required to verify your identity
      </Text>
      <FloatingLabelInput
        Component={TextInput}
        withAsterisk
        mt="md"
        label="Street address line 1"
        {...form.getInputProps("address1")}
      />
      <FloatingLabelInput
        Component={TextInput}
        mt="md"
        label="Street address line 2"
        {...form.getInputProps("address2")}
      />
      <FloatingLabelInput
        Component={TextInput}
        withAsterisk
        mt="md"
        label="City"
        {...form.getInputProps("city")}
      />
      <FloatingSelect
        Component={Select}
        withAsterisk
        mt="md"
        placeholder="State"
        searchable
        floatOnFocus
        forwardRef={stateInputRef}
        data={states.map((state) => ({
          value: state.abbreviation,
          label: state.name,
        }))}
        {...form.getInputProps("state")}
        onSearchChange={(value) => {
          const autoFilled =
            stateInputRef.current?.matches(":-webkit-autofill");

          if (autoFilled) {
            const parent = stateInputRef.current.closest(
              ".mantine-InputWrapper-root"
            );
            if (parent) {
              const dropdown = parent.querySelector(".mantine-Select-dropdown");
              if (dropdown) {
                dropdown.style.display = "none";
              }
              const label = parent.querySelector(".mantine-Select-label");
              if (label) {
                label.setAttribute("data-floating", true);
              }
            }

            const foundState = states.find((obj) => {
              return obj.name === value;
            });

            if (foundState && form?.values?.state !== foundState.abbreviation) {
              form.setFieldValue("state", foundState.abbreviation);
            }
          }
          const stateProps = form.getInputProps("state");
          if (typeof stateProps?.onSearchChange === "function") {
            stateProps.onSearchChange(value);
          }
        }}
      />
      <FloatingLabelInput
        Component={TextInput}
        withAsterisk
        mt="md"
        label="Zip code"
        {...form.getInputProps("zip")}
      />
    </Container>
  );
};

export default CurrentAddress;
