import { useEffect, useState } from "react";

import classes from "@/components/Form/FloatingLabelTextarea/FloatingLabelTextarea.module.css";

export function FloatingLabelTextarea({
  Component,
  Header,
  floatOnFocus,
  floatOnInit,
  labelType,
  ...props
}) {
  const [floating, setFloating] = useState(false);
  const [forceFloating, setForceFloating] = useState(floatOnInit);
  const value = props?.value || "";
  const error = props?.error || "";
  let label = props?.label || "";
  if (!error && props.withAsterisk) {
    label += "*";
  }
  const labelHasValue = value.trim().length !== 0 || error.trim().length !== 0;
  const floatingLabelType = labelType || "data-floating";

  useEffect(() => {
    setFloating(labelHasValue || forceFloating);
  }, [value, error, labelHasValue, forceFloating]);

  return (
    <>
      {Header}
      <Component
        classNames={classes}
        labelProps={{
          [floatingLabelType]: floating,
          "data-error": !!props.error,
        }}
        {...props}
        onFocus={() => {
          if (typeof props?.onFocus === "function") {
            props.onFocus();
          }
          // Errors cheat fix. If the input is focused, the error label will be hidden
          if (typeof props?.onChange === "function") {
            props.onChange(props?.value);
          }
          if (floatOnFocus) {
            setForceFloating(true);
          }
        }}
        onBlur={() => {
          if (typeof props?.onBlur === "function") {
            props.onBlur();
          }
          setFloating(labelHasValue);
          setForceFloating(false);
        }}
        label={props.error || label}
        error={""}
        withAsterisk={false}
      />
    </>
  );
}
