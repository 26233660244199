import {
  Text,
  Group,
  Button,
  Select,
  Textarea,
  LoadingOverlay,
} from "@mantine/core";

import useOptOut from "@/hooks/useOptOut";

const OptOutForm = () => {
  const { form, optOut, isLoading } = useOptOut();

  return (
    <div>
      <LoadingOverlay visible={isLoading} />
      <form onSubmit={form.onSubmit(optOut)}>
        <Text size="xl" fw={700}>
          We understand if Wyndy isn't the right fit for you.
        </Text>
        <Select
          mt="md"
          label="Would you mind sharing why you've opted out of our application?"
          placeholder="Reason"
          data={[
            {
              value: "not-meet-requirements",
              label: "Do not meet requirements",
            },
            {
              value: "not-comfortable-providing-required-information",
              label: "Not comfortable with providing required information",
            },
            { value: "changed-my-mind", label: "Changed my mind" },
            { value: "other", label: "Other" },
          ]}
          {...form.getInputProps("optOutReason")}
        />
        <Textarea
          autosize
          mt="md"
          label="Is there any other feedback you’d like to share with our team?"
          minRows={3}
          {...form.getInputProps("optOutNotes")}
        />
        <Group position="center" mt="md">
          <Button fullWidth type="submit">
            Submit feedback
          </Button>
        </Group>
      </form>
    </div>
  );
};

export default OptOutForm;
