import { createStyles } from "@mantine/core";

const useStyles = createStyles(() => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: 350,
    [`@media (max-width: 480px)`]: {
      maxWidth: "none",
    },
  },
}));
export const Container = (props) => {
  const { classes } = useStyles();

  return <div className={classes.container} {...props} />;
};
