import { useEffect, useState } from "react";
import {
  Text,
  Select,
  Modal,
  CloseButton,
  Checkbox,
  createStyles,
} from "@mantine/core";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import { Container } from "@/components/Form/Container";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { FloatingSelect } from "@/components/Form/FloatingSelect/FloatingSelect";
import variables from "@/config/variables";
import { DateOfBirthInput } from "@/components/Form/DateOfBirthInput";

const useStyles = createStyles(() => ({
  note: {
    fontSize: "13px",
  },
}));

const BasicQualifications = () => {
  const { classes } = useStyles();
  const form = useApplicationFormContext();
  const [modalMessage, seModalMessage] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);

  useProgressBar(3);

  useEffect(() => {
    if (shouldShowModal(form.getInputProps("paidSittingExperience"))) {
      form.clearFieldError("paidSittingExperience");
      seModalMessage(
        "All Wyndy sitters must have childcare experience, please confirm your childcare experience level."
      );
      setModalOpened(true);
    } else if (shouldShowModal(form.getInputProps("reliableTransportation"))) {
      form.clearFieldError("reliableTransportation");
      seModalMessage(
        "All Wyndy sitters must have access to reliable transportation, please confirm if you have access."
      );
      setModalOpened(true);
    }
  }, [form]);

  const shouldShowModal = (inputProps) => {
    return (
      inputProps?.error?.length > 0 &&
      (typeof inputProps?.value === "boolean" || inputProps?.value?.length > 0)
    );
  };

  return (
    <Container>
      <Text size="lg" fw={700}>
        Personal Details
      </Text>
      <DateOfBirthInput
        Component={FloatingSelect}
        withAsterisk
        mt="md"
        label="Birthdate"
        placeholder={"mm/dd/yyyy"}
        {...form.getInputProps("dob")}
      />
      <FloatingSelect
        Component={Select}
        withAsterisk
        mt="md"
        label="Years of paid babysitting experience"
        placeholder="Select Option"
        data={variables.experienceOptions ?? []}
        {...form.getInputProps("paidSittingExperience")}
        error={
          shouldShowModal(form.getInputProps("paidSittingExperience"))
            ? ""
            : form.getInputProps("paidSittingExperience")?.error
        }
      />

      <Text size="md" fw={600} mt="md" mb="xs">
        Do you have reliable transportation?
      </Text>
      <Checkbox
        label="I have reliable transportation"
        checked={form.values.reliableTransportation}
        {...form.getInputProps("reliableTransportation")}
        error={
          shouldShowModal(form.getInputProps("reliableTransportation"))
            ? ""
            : form.getInputProps("reliableTransportation")?.error
        }
      />
      <Text
        mt="sm"
        align="left"
        color="text.3"
        weight="500"
        className={classes.note}
      >
        Reliable transportation includes ridesharing and public transit
      </Text>

      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        size="sm"
        withCloseButton={false}
        centered={true}
      >
        <CloseButton
          aria-label="Close"
          pos="absolute"
          top="5px"
          right="7px"
          onClick={() => {
            setModalOpened(false);
          }}
        />
        <Text size="xl" align="center">
          {modalMessage}
        </Text>
      </Modal>
    </Container>
  );
};

export default BasicQualifications;
