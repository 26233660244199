import { Text, List, Space, Group, Button } from "@mantine/core";
import { FaThumbsUp } from "react-icons/fa";

import useRedirectByStatus from "@/hooks/useRedirectByStatus";
import Loader from "@/components/Loader/Loader";

const Introduction = () => {
  const { isLoading, goToApplication } = useRedirectByStatus();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Text size="xl" fw={700}>
        Welcome to Wyndy!
      </Text>
      <Text mt="md" size="md">
        We’re excited you're interested in becoming a sitter with us.
      </Text>
      <Text size="md" mt="md">
        Our secure online application only takes about 10 minutes to complete
        and includes:
      </Text>
      <List mt="md" mr="xl">
        <List.Item>Personal information and education history</List.Item>
        <List.Item>
          A short, recorded video interview for you to share your childcare
          experience
        </List.Item>
        <List.Item>Consent to run a basic background check </List.Item>
      </List>
      <Space h="md" />
      <Group position="center" mt="md">
        <Button
          fullWidth
          type="submit"
          leftIcon={<FaThumbsUp />}
          onClick={goToApplication}
        >
          Get Started
        </Button>
      </Group>
    </div>
  );
};

export default Introduction;
