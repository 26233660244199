import { Text, Alert, Space, TextInput } from "@mantine/core";
import InputMask from "react-input-mask";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { FloatingLabelInput } from "@/components/Form/FloatingLabelInput/FloatingLabelInput";
import { DateOfBirthInput } from "@/components/Form/DateOfBirthInput";
import { FloatingSelect } from "@/components/Form/FloatingSelect/FloatingSelect";

const Review = () => {
  const form = useApplicationFormContext();
  useProgressBar(15);

  return (
    <div>
      <Text size="lg" fw={700} align="center">
        Review Your Application
      </Text>

      <Alert mt="sm" color="blue">
        Please review your application to ensure it is accurate. If everything
        is correct, press the 'Continue' button at the bottom of the page.
      </Alert>

      <Space h="md" mb="sm" />
      <Text size="md" mb="sm" fw={600}>
        Legal Information
      </Text>
      <FloatingLabelInput
        Component={TextInput}
        placeholder="Legal First Name"
        label="Legal First Name"
        {...form.getInputProps("legalFirstName")}
      />
      <Space h="sm" />
      <FloatingLabelInput
        Component={TextInput}
        placeholder="Legal Last Name"
        label="Legal Last Name"
        {...form.getInputProps("legalLastName")}
      />
      <Space h="sm" />
      <DateOfBirthInput
        Component={FloatingSelect}
        placeholder={"Date of Birth"}
        {...form.getInputProps("dob")}
      />
      <Space h="sm" />
      <FloatingLabelInput
        Component={TextInput}
        component={InputMask}
        floatOnFocus
        label="Phone Number"
        mask="999-999-9999"
        value={form?.values?.phone}
        {...form.getInputProps("phone")}
      />
      <Space h="sm" />
      <FloatingLabelInput
        Component={TextInput}
        component={InputMask}
        floatOnFocus
        label="Social Security Number"
        mask="999-99-9999"
        maskPlaceholder=""
        {...form.getInputProps("ssn")}
      />
      <Space h="sm" />
      <FloatingLabelInput
        Component={TextInput}
        label="Driver's License Number"
        {...form.getInputProps("driversLicenseNo")}
      />
    </div>
  );
};

export default Review;
