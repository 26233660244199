import { createContext, useContext, useEffect, useRef, useState } from "react";

export const ProgressBarContext = createContext({
  currentStep: null,
  setCurrentStep: () => {},
});

export function useProgressBar(currentStep) {
  const context = useContext(ProgressBarContext);

  useEffect(() => {
    context.setCurrentStep(currentStep);
    if (context.resetTimeout.current) {
      clearTimeout(context.resetTimeout.current);
    }
    return () => {
      context.resetTimeout.current = setTimeout(() => {
        context.setCurrentStep(null);
      }, 1000);
    };
  }, [currentStep, context]);

  return context;
}

export function ProgressBarProvider({ children, ...props }) {
  const [currentStep, setCurrentStep] = useState(null);
  const resetTimeout = useRef(null);

  return (
    <ProgressBarContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        resetTimeout,
      }}
      {...props}
    >
      {children}
    </ProgressBarContext.Provider>
  );
}
