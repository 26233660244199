import { Text, TextInput, Select } from "@mantine/core";
import states from "states-us";
import InputMask from "react-input-mask";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { FloatingLabelInput } from "@/components/Form/FloatingLabelInput/FloatingLabelInput";
import { FloatingSelect } from "@/components/Form/FloatingSelect/FloatingSelect";

const CurrentAddress = () => {
  const form = useApplicationFormContext();
  useProgressBar(11);

  return (
    <div>
      <Text size="lg" fw={700}>
        Legal Details
      </Text>
      <Text size="md" mt="md" fw={500}>
        We securely process your information for enrollment verification,
        background checks, and payment
      </Text>
      <FloatingLabelInput
        Component={TextInput}
        component={InputMask}
        withAsterisk
        floatOnFocus
        mt="md"
        label="Social Security Number"
        mask="999-99-9999"
        maskPlaceholder=""
        {...form.getInputProps("ssn")}
      />
      <FloatingLabelInput
        Component={TextInput}
        mt="md"
        label="Driver's License Number"
        {...form.getInputProps("driversLicenseNo")}
      />
      <FloatingSelect
        Component={Select}
        mt="md"
        placeholder="Driver's License State"
        searchable
        floatOnFocus
        data={states.map((state) => ({
          value: state.abbreviation,
          label: state.name,
        }))}
        {...form.getInputProps("driversLicenseState")}
      />
    </div>
  );
};

export default CurrentAddress;
