import { useRecoilState, useResetRecoilState } from "recoil";

import authState from "@/atoms/auth.atom";
import applicationState from "@/pages/Application/atoms/application.atom";
import variables from "@/config/variables";

const useAuth = () => {
  const [auth, setAuth] = useRecoilState(authState);

  const handleAuth = (data) => setAuth({ ...data, isAuth: true });
  const resetAuth = useResetRecoilState(authState);
  const resetFlow = useResetRecoilState(applicationState);

  const logout = () => {
    resetAuth();
    resetFlow();
    window.localStorage.removeItem(variables.localStorage.APPLICATION_FORM);
    window.localStorage.removeItem(variables.localStorage.AUTH);
  };

  return {
    auth,
    logout,
    setAuth: handleAuth,
  };
};

export default useAuth;
