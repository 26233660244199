import { Button, Text, Flex } from "@mantine/core";

const Signature = ({
  legalName,
  error,
  label = "Signature",
  onSign = () => {},
}) => {
  const signatureState = legalName ? (
    <Text align="center">{legalName}</Text>
  ) : (
    <Button
      color={error && "red"}
      fullWidth
      compact
      className="outlined"
      variant="outline"
      onClick={onSign}
    >
      Click here to Sign
    </Button>
  );

  return (
    <Flex direction="column" gap={5} w="100%">
      <Text color="gray" size="xs" fw={500} align="center">
        {label}
      </Text>
      {signatureState}
      {error && (
        <Text color="red" size="xs">
          {error}
        </Text>
      )}
    </Flex>
  );
};

export default Signature;
