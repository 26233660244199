import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Alert, Button, Group, Paper, Space } from "@mantine/core";

export const CheckoutForm = ({
  clientSecret,
  onSubmit,
  onChange,
  onReady,
  ...props
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null || stripe == null) {
      return;
    }

    setLoading(true);

    try {
      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError?.message) {
        setLoading(false);
        return;
      }

      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: `${window.location.href}`,
        },
        redirect: "if_required",
      });

      if (result.error) {
        setErrorMessage(result.error.message);
      } else {
        if (typeof onSubmit === "function") {
          onSubmit(event);
        }
      }

      setLoading(false);
    } catch (error) {
      if (typeof onReady === "function") {
        onReady(event);
      }

      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <Paper radius="xs" px={20} py={20} withBorder>
        <PaymentElement
          {...props}
          onReady={onReady}
          onChange={(event) => {
            setErrorMessage("");

            if (typeof onChange === "function") {
              onChange(event);
            }
          }}
        />

        {errorMessage.length > 0 && (
          <Alert
            variant="filled"
            color="red"
            mt="xs"
            title="Add Payment Method Error"
          >
            {errorMessage}
          </Alert>
        )}
        <Space h="xs" />
        <Group position="center" mt="xs">
          <Button fullWidth onClick={handleSubmit} loading={loading}>
            Continue
          </Button>
        </Group>
      </Paper>
    </>
  );
};
