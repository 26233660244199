import variables from "@/config/variables";

const getLocalStorageAuth = () => {
  const authData = JSON.parse(
    window.localStorage.getItem(variables.localStorage.AUTH)
  );
  return authData?.authState;
};

export { getLocalStorageAuth };
