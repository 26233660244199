const globalStyles = [
  {
    ".myInterviewButton": {
      backgroundColor: "#09ADC3",
      textDecoration: "none",
      color: "white",
      borderRadius: "5px",
      padding: "10px",
    },
  },
];

export default globalStyles;
