import moment from "moment";
import {
  Text,
  Avatar,
  Center,
  List,
  Divider,
  Flex,
  Button,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";

import ListItem from "@/components/ListItem/ListItem";
import Loader from "@/components/Loader/Loader";
import useGetUserData from "@/hooks/useGetUserData";
import useMarkets from "@/hooks/useGetMarkets";

const SubmissionProfile = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetUserData();
  const { markets, isLoadingMarkets } = useMarkets();

  const handleGoBack = () => navigate(-1);

  if (isLoading || isLoadingMarkets) {
    return <Loader />;
  }

  return (
    <div style={{ position: "relative" }}>
      <Text size="xl" fw={700}>
        My Application
      </Text>
      <Center mt="xl">
        <Avatar size={200} radius={10} src={data?.imageUrl} />
      </Center>
      <List mt="xl" spacing="xs" size="sm" center icon={<></>}>
        <Divider labelPosition="center" my="md" label="Name" variant="dashed" />
        <ListItem label="First Name" value={data?.firstName} />
        <ListItem label="Last Name" value={data?.lastName} />
        <Divider
          labelPosition="center"
          my="md"
          label="Contact Information"
          variant="dashed"
        />
        <ListItem label="Phone Number" value={data?.phone} />
        <Divider
          labelPosition="center"
          my="md"
          label="Where you want to be a Wyndy"
          variant="dashed"
        />
        <ListItem
          label="Market"
          value={markets?.find((market) => market.id === data?.marketId)?.name}
        />
        <Divider
          labelPosition="center"
          my="md"
          label="Basic qualifications"
          variant="dashed"
        />
        <ListItem label="Birth Date" value={moment(data?.dob).format("LL")} />
        <ListItem
          label="Reliable Transportation"
          value={data?.reliableTransportation ? "Yes" : "No"}
        />
        <ListItem
          label="College Level"
          value={data?.collegeLevel?.replace(/-/g, " ")}
        />
        <ListItem
          label="Babysitting Experience"
          value={`${data?.paidSittingExperience
            ?.replace(/_/g, " ")
            .replace(/y/g, " years")}`}
        />
        <Divider
          labelPosition="center"
          my="md"
          label="Legal Name"
          variant="dashed"
        />
        <ListItem label="Legal First Name" value={data?.legalFirstName} />
        {data?.legalMiddleName ? (
          <ListItem label="Legal Middle Name" value={data?.legalMiddleName} />
        ) : null}
        <ListItem label="Legal Last Name" value={data?.legalLastName} />
        <Divider
          labelPosition="center"
          my="md"
          label="Address"
          variant="dashed"
        />
        <ListItem label="Street address" value={data?.address1} />
        {data?.address2 ? (
          <ListItem label="Street address line 2" value={data?.address2} />
        ) : null}
        <ListItem label="City" value={data?.city} />
        <ListItem label="State" value={data?.state} />
        <ListItem label="Zip code" value={data?.zip} />

        <Divider
          labelPosition="center"
          my="md"
          label="Identity"
          variant="dashed"
        />
        <ListItem label="Social Security Number" value={data?.ssn} />
        {data?.driversLicenseNo && data?.driversLicenseState && (
          <ListItem
            label="Driver License"
            value={`${data?.driversLicenseNo} ${data?.driversLicenseState}`}
          />
        )}
      </List>
      <Flex mt="md" direction="column" align="center">
        <Button fullWidth onClick={handleGoBack}>
          Go Back
        </Button>
      </Flex>
    </div>
  );
};

export default SubmissionProfile;
