import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import applicationFlow from "@/pages/Application/flow";
import useGetUserData from "@/hooks/useGetUserData";
import useAuth from "@/hooks/useAuth";

const useRedirectByStatus = () => {
  const { data, isLoading } = useGetUserData();
  const { auth } = useAuth();
  const navigate = useNavigate();

  const goToApplication = () =>
    navigate(`/application/${applicationFlow[0].path}`);
  const goToDashboard = () => navigate(`/dashboard`);
  const goToIntroduction = () => navigate(`/introduction`);
  const goToRejectedQualifications = () =>
    navigate(`/basic-qualifications-denied`);
  const goToOptOut = () => navigate(`/application/opt-out`);
  const goToOptedOut = () => navigate(`/opted-out`);
  const goToLogin = () => navigate(`/login`);

  useEffect(() => {
    if (!auth.isAuth) {
      return goToLogin();
    }

    if (!isLoading) {
      // If user has opted out of the application
      if (data?.status && data?.status === "opted_out") {
        return goToOptedOut();
      }

      // If basic qualifications are not meet
      if (
        data?.status &&
        (data?.status === "rejected" || data.status === "auto_rejected") &&
        !data.qualified
      ) {
        return goToRejectedQualifications();
      }

      // If application process has been started redirect to first step
      if (!data?.status) {
        return goToIntroduction();
      }

      // If application process has been started redirect to first step
      if (data?.status && data?.status === "started") {
        return goToApplication();
      }

      // If the application process has been submitted then redirect to the dashboard
      if (data?.status && data?.status !== "started") {
        return goToDashboard();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    data,
    isLoading,
    goToApplication,
    goToDashboard,
    goToOptOut,
  };
};

export default useRedirectByStatus;
