import { useEffect } from "react";
import { Text, ScrollArea, Flex, Group, Paper, Checkbox } from "@mantine/core";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import Signature from "@/components/Signature/Signature";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { Container } from "@/components/Form/Container";

const BackgroundCheckAuthorization = () => {
  const form = useApplicationFormContext();
  useProgressBar(14);

  const legalName = form?.values?.legalFirstName
    ? `${form?.values?.legalFirstName}${
        form?.values?.legalMiddleName && ` ${form?.values?.legalMiddleName}`
      } ${form?.values?.legalLastName}`
    : "";

  const handleSign = () => {
    form.setFieldValue("signature", legalName);
  };

  useEffect(() => {
    if (form.values.signature && form.values.signature !== legalName) {
      form.setFieldValue("signature", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values]);

  return (
    <Container>
      <Text size="lg" fw={700}>
        Background Check Authorization
      </Text>
      <Paper mt="md" shadow="sm" radius="sm" withBorder>
        <Group p="xs">
          <ScrollArea style={{ height: 200 }} type="auto" p="0">
            <Flex
              gap="md"
              justify="flex-start"
              align="flex-start"
              direction="row"
              wrap="wrap"
            >
              <Text
                style={{ fontSize: "12px", overflowWrap: "anywhere" }}
                color="placeholder.0"
              >
                <h3 class="my-4" style={{ marginTop: 0 }}>
                  Acknowledgment and Authorization for Background Check
                </h3>
                <div class="scrolling-content mb-8">
                  <p>
                    I acknowledge receipt of the separate document entitled
                    DISCLOSURE REGARDING BACKGROUND INVESTIGATION and A SUMMARY
                    OF YOUR RIGHTS UNDER THE FAIR CREDIT REPORTING ACT and
                    certify that I have read and understand both of those
                    documents. I hereby authorize the obtaining of “consumer
                    reports” and/or “investigative consumer reports” by the
                    Company at any time after receipt of this authorization and
                    throughout my employment, if applicable. To this end, I
                    hereby authorize, without reservation, any law enforcement
                    agency, administrator, state or federal agency, institution,
                    school or university (public or private), information
                    service bureau, employer, or insurance company to furnish
                    any and all background information requested by Checkr,
                    Inc., 2505 Mariposa St. Floor 2, San Francisco, CA 94110 |
                    844-824-3247 | <a href="https://checkr.com">checkr.com</a>.
                    I agree that an electronic copy of this Authorization shall
                    be as valid as the original.
                  </p>
                  <h3 class="my-4">Massachusetts applicants only</h3>
                  <p>
                    Upon written request to the Company, you have the right to
                    know whether the Company ordered an investigative consumer
                    report about you. You also have the right to request from
                    the consumer reporting agency a copy of any such report.
                  </p>
                  <h3 class="my-4">Minnesota applicants only</h3>
                  <p>
                    Upon a written request, you have the right to a complete and
                    accurate disclosure of the nature and scope of any consumer
                    report the Company ordered about you. The consumer reporting
                    agency must provide you with this disclosure within 5
                    business days after its receipt of your request or the
                    report was requested by the Company, whichever date is
                    later.
                  </p>
                  <h3>New Jersey applications only</h3>
                  <p>
                    You have the right to submit a request to the CRA for a copy
                    of any investigative consumer report the Company ordered
                    about you.
                  </p>
                  <h3 class="my-4">New York applicants only</h3>
                  <p>
                    Upon request, you will be informed whether or not a consumer
                    report was requested by the Company, and if such report was
                    requested, informed of the name and address of the consumer
                    reporting agency that furnished the report. You have the
                    right to inspect and receive a copy of any investigative
                    consumer report requested by the Company by contacting the
                    consumer reporting agency identified above directly. By
                    signing below, you acknowledge receipt of{" "}
                    <a href="https://www.labor.ny.gov/formsdocs/wp/correction-law-article-23a.pdf">
                      Article 23-A{" "}
                    </a>
                    of the New York Correction Law
                  </p>
                  <h3 class="my-4">Washington State applicants only</h3>
                  <p>
                    Upon written request to Company, you have the right to a
                    complete and accurate disclosure of the nature and scope of
                    any investigative consumer report the Company ordered about
                    you. You are entitled to this disclosure within 5 business
                    days after the date your request is received or we ordered
                    the report, whichever is later. You also have the right to
                    request from the consumer reporting agency a written summary
                    of your rights and remedies under the Washington Fair Credit
                    Reporting Act.
                  </p>
                </div>
              </Text>
            </Flex>
          </ScrollArea>
        </Group>
      </Paper>
      {/*<Space h="md" />*/}
      {/*<Notification disallowClose>*/}
      {/*  <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide">*/}
      {/*    We don’t want Wyndy to cost you anything unless it delivers value to*/}
      {/*    you. As a result, we only deduct the cost of your background check*/}
      {/*    ($15) after you’ve completed your first job. Once that one-time fee is*/}
      {/*    deducted from your first completed job, you’ll keep 100% of your*/}
      {/*    earnings (plus 100% of any bonuses you receive from happy parents)!*/}
      {/*  </Spoiler>*/}
      {/*</Notification>*/}
      <Group mt="md">
        <Checkbox
          label="Please check this box if you would like to receive a copy of your
          consumer report if one is obtained by Wyndy"
          checked={form.values.copyRequested}
          className="align-top"
          {...form.getInputProps("copyRequested")}
        />
        {/* <TextInput
          withAsterisk
          mt="md"
          description={`Signature must match your legal name ${legalName}`}
          {...form.getInputProps("signature")}
        /> */}

        <Signature
          fullWidth
          legalName={form.values.signature}
          label=""
          onSign={handleSign}
          {...form.getInputProps("signature")}
        />
      </Group>
    </Container>
  );
};

export default BackgroundCheckAuthorization;
