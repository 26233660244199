import { gql } from "graphql-request";
import { showNotification } from "@mantine/notifications";

import { gqlRequest } from "@/utils/request";

export const getUserStripeAccount = async ({ returnUrl }) => {
  const variables = { returnUrl };

  const query = gql`
    query ($returnUrl: String!) {
      sitter {
        stripeAccount {
          id
          verificationNeeded
          disabledReason
          cards {
            id
            last4
            brand
          }
          stripeDashboardUrl(returnUrl: $returnUrl)
        }
      }
    }
  `;

  try {
    const data = await gqlRequest.request(query, variables);
    return data?.sitter.stripeAccount;
  } catch (err) {
    showNotification({
      color: "red",
      title: "Data fetch error",
      message: "Something wen't wrong, please refresh the page 😟",
    });
  }
  return {};
};

export const createExternalAccount = async (fields) => {
  const mutation = gql`
    mutation sitterSetExternalAccount($cardToken: String!) {
      sitterSetExternalAccount(cardToken: $cardToken) {
        stripeAccount {
          id
          cards {
            id
          }
        }
        errors {
          field
          message
        }
      }
    }
  `;

  try {
    const data = await gqlRequest.request(mutation, fields);
    return data?.sitterSetExternalAccount;
  } catch (err) {
    console.log(err);
    showNotification({
      color: "red",
      title: "Save Error",
      message: "Something wen't wrong, please refresh the page 😟",
    });
  }
  return {};
};
