import { Text, Group, List } from "@mantine/core";

const ListItem = ({ label, value }) => {
  return (
    <List.Item>
      <Group noWrap>
        <div>
          <Text>{label}</Text>
          <Text
            sx={{ textTransform: "capitalize" }}
            size="sm"
            color="dimmed"
            weight={400}
          >
            {value}
          </Text>
        </div>
      </Group>
    </List.Item>
  );
};

export default ListItem;
