const variables = {
  localStorage: {
    AUTH: "wyndy-sitter-application-auth",
    APPLICATION_FORM: "wyndy-sitter-application-flow",
    EXTERNAL_ZIP_CODE: "wyndy-external-zip-code",
  },
  stepsCount: 16,
  dateFormatChars: {
    n: "[0-1]",
    m: "[0-9]",
    e: "[0-3]",
    d: "[0-9]",
    z: "[1-2]",
    y: "[0-9]",
  },
  reliableTransportationOptions: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  experienceOptions: [
    { value: "none", label: "None" },
    { value: "less_than_1y", label: "Less than 1 year" },
    { value: "1y", label: "1 year" },
    { value: "2y", label: "2 years" },
    { value: "3y", label: "3 years" },
    { value: "4y", label: "4 years" },
    { value: "5y_or_more", label: "5+ years" },
  ],
  collegeLevelOptions: [
    {
      value: "accepted",
      label: "Accepted for upcoming semester",
    },
    { value: "enrolled", label: "Currently enrolled" },
    {
      value: "previously_enrolled",
      label: "Previously enrolled, not currently attending",
    },
    { value: "graduated", label: "Graduated" },
    { value: "none", label: "None of the above" },
  ],
};

export default variables;
