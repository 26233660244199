import { Text, Group, Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const MarketUnavailable = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <Text size="xl" fw={700}>
        Thanks for your interest in Wyndy!
      </Text>
      <Text mt="md" size="md" weight="500">
        It looks like we aren't in your city quite yet but we're always
        expanding.
      </Text>
      <Group position="center" mt="md">
        <Button
          compact
          fullWidth
          color="brand"
          variant="subtle"
          leftIcon={<FaArrowLeft />}
          onClick={goBack}
        >
          Back
        </Button>
      </Group>
    </div>
  );
};

export default MarketUnavailable;
