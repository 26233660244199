import axios from "axios";
import { GraphQLClient } from "graphql-request";

import { getLocalStorageAuth } from "@/utils/auth";
import {
  transformAttributes,
  transformResponseAttributes,
  transformBody,
} from "@/utils/regex";

const HOST = process.env.REACT_APP_BACKEND_URL;
const NAMESPACE = "api";

const request = (version = "v1") => {
  const client = axios.create({
    baseURL: `${HOST}/${NAMESPACE}/${version}`,
    mode: "no-cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    Response: [transformResponseAttributes],
  });

  const handleRequestSuccess = (res) => res;

  const handleRequestError = (err) => {
    throw err;
  };

  const interpolateURI = (url, id) => {
    const action = url.replace(":id", id);
    return action;
  };

  // Set authentication
  const setAuthHeaders = (config) => {
    const user = getLocalStorageAuth();

    if (user) {
      config.url = interpolateURI(config.url, user.id);
      config.headers.Authorization = `Token token="${user.token}", email="${user.email}"`;
    }

    return config;
  };

  // Request use middleware
  client.interceptors.request.use(setAuthHeaders);
  client.interceptors.response.use(handleRequestSuccess, handleRequestError);

  // Request types
  const get = (uri, params = {}) => client.get(uri, { params });
  const put = (uri, _params, body) => client.put(uri, transformBody(body));
  const deleteFn = (uri, params = {}) => client.delete(uri, params);
  const post = (uri, params = {}, body) => {
    const requestBody = params.preformattedBody
      ? body
      : { data: { attributes: transformAttributes(body, /_/g, "-") } };
    return client.post(uri, requestBody);
  };

  return {
    get,
    put,
    post,
    delete: deleteFn,
  };
};

export const gqlRequest = new GraphQLClient(`${HOST}/graphql`, {
  headers: () => {
    const user = getLocalStorageAuth();
    if (user?.isAuth) {
      return {
        Authorization: `Token token="${user.token}"`,
      };
    }
    return {};
  },
});

export const gqlPublicRequest = new GraphQLClient(`${HOST}/public/graphql`);

export default request;
