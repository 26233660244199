import {
  Text,
  Divider,
  Button,
  Flex,
  Anchor,
  Center,
  Image,
} from "@mantine/core";
import { FaUserCircle } from "react-icons/fa";

import { useProgressBar } from "@/contexts/ProgressBarContext";
import { Container } from "@/components/Form/Container";
import happyGirlImage from "@/assets/images/happy-girl.svg";
import Loader from "@/components/Loader/Loader";
import useRedirectByStatus from "@/hooks/useRedirectByStatus";

const Dashboard = () => {
  useProgressBar(16);

  const { isLoading } = useRedirectByStatus();

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Container>
      <Center>
        <Image
          width={300}
          src={happyGirlImage}
          styles={(theme) => ({
            root: {
              width: "100%",
              minWidth: "auto",
            },
          })}
        />
      </Center>
      <Text size="34px" fw={700} ta="center">
        Application Received.
      </Text>
      <Text size="md" mt="md" fw={500} ta="center">
        Thank you for applying to be a Wyndy! You will receive an email once
        your application has been reviewed by our team.
      </Text>
      <Divider
        labelPosition="center"
        my="lg"
        size="sm"
        label="Wyndy profile"
        labelProps={{ size: "md" }}
        hidden={true}
      />
      <Flex hidden={true} mt="md" direction="column" align="center">
        <Anchor href="/profile">
          <Button leftIcon={<FaUserCircle />} size="md">
            View / Edit Profile
          </Button>
        </Anchor>
      </Flex>
    </Container>
  );
};

export default Dashboard;
