import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

import variables from "@/config/variables";

const { persistAtom } = recoilPersist({
  key: variables.localStorage.AUTH,
  storage: localStorage,
});

const authState = atom({
  key: "authState",
  default: {
    isAuth: false,
    status: undefined,
    qualified: undefined,
    firstName: undefined,
    lastName: undefined,
    token: undefined,
  },
  effects_UNSTABLE: [persistAtom],
});

export default authState;
