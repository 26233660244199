import { Navigate, Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";

import authState from "@/atoms/auth.atom";

const ProtectedRoute = ({ redirectPath = "/login", children }) => {
  const auth = useRecoilValue(authState);

  if (!auth.isAuth) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
