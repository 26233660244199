import { useState } from "react";
import { Text, Select } from "@mantine/core";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import useGetColleges from "@/hooks/useGetColleges";
import { Container } from "@/components/Form/Container";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { FloatingSelect } from "@/components/Form/FloatingSelect/FloatingSelect";
import variables from "@/config/variables";

const EducationDetails = () => {
  const form = useApplicationFormContext();
  const [searchCollegeString, setSearchCollegeString] = useState(
    form.values?.collegeName || ""
  );
  const { colleges, isLoadingColleges } = useGetColleges(searchCollegeString);

  useProgressBar(4);

  const searchColleges = (value) => {
    if (!isLoadingColleges) {
      setSearchCollegeString(value);
    }
  };

  const getColleges = () => {
    return (colleges ?? []).map((item) => ({
      value: item.id,
      label: item.title,
    }));
  };

  return (
    <Container>
      <Text size="lg" fw={700}>
        Education Details
      </Text>

      <FloatingSelect
        Component={Select}
        withAsterisk
        mt="md"
        label="Select your level of college experience"
        placeholder="Select Option"
        data={variables.collegeLevelOptions}
        {...form.getInputProps("collegeLevel")}
      />
      <FloatingSelect
        Component={Select}
        withAsterisk
        mt="md"
        label="Search your college"
        placeholder="Search College"
        searchable
        floatOnFocus
        onSearchChange={searchColleges}
        data={getColleges()}
        {...form.getInputProps("collegeId")}
      />
    </Container>
  );
};

export default EducationDetails;
