import { Text, Group, Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Container } from "@/components/Form/Container";
import { MobilePush } from "@/components/Common/MobilePush";

const OptOutConfirmation = () => {
  const navigate = useNavigate();
  const optOutApplication = () => {
    navigate("/application/opt-out-form");
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Text size="xl" fw={700}>
        We’re sorry to hear that!
      </Text>
      <Text mt="md" size="sm" weight="500">
        Unfortunately, we’re unable to process your application without this
        information.
      </Text>
      <Text mt="md" size="sm" weight="500">
        Wyndy takes privacy and security very seriously, and follows strict
        cybersecurity practices to ensure all information is used on a
        need-to-know basis for verifying identity.
      </Text>
      <Text mt="md" size="sm" weight="500">
        If you choose to opt out now, you can always come back and restart your
        application where you left off.
      </Text>

      <MobilePush />

      <Group position="center" mt="md">
        <Button fullWidth onClick={goBack}>
          Return to my application
        </Button>
        <Button
          compact
          fullWidth
          color="red"
          variant="subtle"
          onClick={optOutApplication}
        >
          Opt out for now
        </Button>
      </Group>
    </Container>
  );
};

export default OptOutConfirmation;
